import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import useUser from '../zustand/useUser';

const NewImageSlider = () => {
  const { product } = useUser();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === product?.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? product?.length - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    let interval;
    if (isAutoPlaying) {
      interval = setInterval(() => {
        nextSlide();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [currentSlide, isAutoPlaying]);

  return (
    <div className="relative w-full h-[85vh]  md:h-[85vh] overflow-hidden group">
      {/* Slider container */}
      <div
        className="flex transition-transform  duration-700 ease-in-out h-full"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {product?.map((slide) => (
          <div key={slide._id} className="w-full flex-shrink-0 relative h-full">
            {/* Image with overlay */}
            <div className="relative w-full h-full">
              <div className="relative w-full h-full">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="w-full h-full object-cover"
                />
                {/* <div className="absolute inset-0 bg-gradient-to-r from-black/50 to-transparent pointer-events-none" /> */}
              </div>
              {/* Different overlay for mobile vs desktop */}
              {/* {isMobile ? (
                                <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/20 to-black/40"></div>
                            ) : (
                                <>
                                    <div className="absolute inset-0 bg-gradient-to-r from-black/70 via-black/40 to-transparent"></div>
                                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20"></div>
                                </>
                            )} */}
            </div>

            {/* Content - Responsive layout */}
            {/* <div className={`absolute inset-0 flex flex-col justify-center items-${isMobile ? 'center text-center' : 'start text-left'} text-white pb-24 px-6 md:pl-40 max-w-6xl`}>
                            <h2 className={`${isMobile ? 'text-2xl' : 'text-6xl'} font-bold font-roboto mb-2`}>
                                {(slide.title + ' : ' + slide.shortDescription.slice(0, 50))}
                            </h2>
                            <div className='h-[7px] bg-white w-[60px] mb-[20px]'></div>
                            <p className={`${isMobile ? 'text-sm' : 'text-xl'} mb-4 md:mb-8 max-w-2xl text-gray-200`}>
                                {isMobile ? (slide.shortDescription) : slide.shortDescription}
                            </p>
                            <button
                                onClick={() => navigate(`/product/${slide._id}`)}
                                className={`px-4 py-2 hover:opacity-70 md:px-8 md:py-3 bg-white text-black font-medium  hover:bg-gray-100 transition transform hover:scale-105 text-sm md:text-base mx-auto md:mx-0`}
                            >
                                Explore More
                            </button>
                        </div> */}
          </div>
        ))}
      </div>

      {/* Navigation Arrows - Visible on both mobile and desktop */}
      <button
        onClick={prevSlide}
        className={`absolute left-2 md:left-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 md:p-3 rounded-full hover:bg-black/70 transition ${
          isMobile ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
        } z-10`}
        onMouseEnter={() => !isMobile && setIsAutoPlaying(false)}
        onMouseLeave={() => !isMobile && setIsAutoPlaying(true)}
        aria-label="Previous slide"
      >
        <FiChevronLeft size={isMobile ? 20 : 28} />
      </button>
      <button
        onClick={nextSlide}
        className={`absolute right-2 md:right-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 md:p-3 rounded-full hover:bg-black/70 transition ${
          isMobile ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
        } z-10`}
        onMouseEnter={() => !isMobile && setIsAutoPlaying(false)}
        onMouseLeave={() => !isMobile && setIsAutoPlaying(true)}
        aria-label="Next slide"
      >
        <FiChevronRight size={isMobile ? 20 : 28} />
      </button>

      {/* Thumbnail Navigation - Visible on both mobile and desktop */}
      <div className="absolute bottom-0 left-0 bg-gray-900  bg-opacity-30 right-0 z-10">
        <div className="container mx-auto px-2 py-9 overflow-x-auto no-scrollbar">
          <div className="flex space-x-2 md:space-x-4 justify-start md:justify-center pl-4 md:pl-0">
            {product?.map((slide, index) => (
              <button
                key={slide.id}
                onClick={() => goToSlide(index)}
                className={`relative flex-shrink-0 w-16 h-10 md:w-40 md:h-20 overflow-hidden transition-all duration-300 ${
                  index === currentSlide
                    ? 'ring-2 md:ring-4 ring-gray-300 scale-105 md:scale-110'
                    : 'opacity-70 hover:opacity-100'
                }`}
                onMouseEnter={() => !isMobile && setIsAutoPlaying(false)}
                onMouseLeave={() => !isMobile && setIsAutoPlaying(true)}
                aria-label={`Go to slide ${index + 1}`}
              >
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="w-full h-full object-cover"
                />
                {index === currentSlide && (
                  <div className="absolute inset-0 bg-white/20"></div>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Slide Indicator - Visible on both mobile and desktop */}
      <div
        className={`absolute ${
          isMobile ? 'bottom-24' : 'bottom-36'
        } left-1/2 -translate-x-1/2 bg-black/50 text-white px-3 py-1 rounded-full text-xs md:text-sm z-10`}
      >
        {currentSlide + 1} / {product?.length}
      </div>
    </div>
  );
};

export default NewImageSlider;
