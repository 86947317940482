import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaShare } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Footer from '../component/Footer';
import Navigation from '../component/Navigation';

export default function ViewProduct() {
  const [product, setProduct] = useState();
  const { id } = useParams();
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/product/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProduct();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="w-full bg-[#F8F9FA]  overflow-x-hidden">
      <div className="px-6 w-full">
        <motion.div
          initial={{ y: '-100%', opacity: 0 }} // Start off-screen above
          animate={{ y: 0, opacity: 1 }} // End at the original position
          transition={{
            type: 'spring', // Smooth spring animation
            stiffness: 80, // Snappy start
            damping: 25, // Smooth stop
            duration: 1.5, // Duration of animation
          }}
        >
          <Navigation value={true} />
        </motion.div>
      </div>
      <div className="md:p-32 p-8 md:mt-20 mt-8  flex md:flex-row flex-col justify-between pt-8 md:pt-20">
        <motion.div
          className="relative w-[300px]  h-[270px] pb-10 md:w-[500px] md:h-[450px]  bg-gray-300 "
          initial={{ x: '-100%', opacity: 0 }} // Start from the left off-screen
          whileInView={{ x: 0, opacity: 1 }} // Animate to the original position when in view
          viewport={{ once: true }} // Trigger animation once when in the viewport
          transition={{
            type: 'spring', // Smooth spring animation
            stiffness: 80, // Snappy start
            damping: 25, // Smooth stop
            duration: 1.5, // Duration of animation
          }}
        >
          <img
            className="absolute bottom-5 left-6 md:bottom-20 object-cover border-[1px] border-gray-300 md:left-24 bg-gray-300   w-[300px] h-[270px] md:w-[500px] md:h-[450px] shadow-image "
            src={product?.image}
            alt=""
          />
          <div className="absolute md:-top-16 md:-right-20 -right-2 top-0 h-8 w-8 flex items-center justify-center p-2 rounded-full bg-[#c1d3e0]">
            <FaShare className="text-xl" />
          </div>
          <div className="absolute md:bottom-24 bottom-8 left-10 md:left-28 text-sm px-3 py-1 bg-[#c1d3e0] rounded-full">
            {product?.country}
          </div>
        </motion.div>
        <motion.div
          className="text-gray-900 md:pt-0 pt-10 w-full md:w-[40%]"
          initial={{ x: '100%', opacity: 0 }} // Start from the right off-screen
          whileInView={{ x: 0, opacity: 1 }} // Animate to the original position when in view
          viewport={{ once: true }} // Trigger animation once when in the viewport
          transition={{
            type: 'spring', // Smooth spring animation
            stiffness: 80, // Snappy start
            damping: 25, // Smooth stop
            duration: 1.5, // Duration of animation
          }}
        >
          <div className="text-3xl md:text-5xl font-bold">{product?.title}</div>
          <div className=" text-2xl md:text-3xl pt-8">
            {product?.shortDescription}
          </div>
        </motion.div>
      </div>
      <div className="flex justify-center">
        <div className="mt-4 flex shadow-2xl md:w-[80%] px-10 mb-10 items-center py-10  w-full justify-center md-editor-light">
          <div className="markdown-preview">
            <MDEditor.Markdown
              source={product?.content}
              style={{
                whiteSpace: 'pre-wrap',
                backgroundColor: '#F8F9FA',
                color: '#000000',
                padding: '1rem',
                borderRadius: '5px',
              }}
            />
            {/* <MDEditor.Markdown source={product?.content} style={{ whiteSpace: 'pre-wrap' }} /> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
