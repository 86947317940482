import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Navigation from '../../component/Navigation';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/auth/forgot-password', { email });
      if (response.status === 200) {
        setSuccessMessage(
          'A magic link has been sent to your email. Please check your inbox.'
        );
      }
    } catch (err) {
      if (err.response) {
        setErrorMessage(
          err.response.data.message || 'An error occurred. Please try again.'
        );
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex bg-gray-200 flex-col items-center  min-h-screen  ">
      <div className="px-6 w-full mb-32">
        <motion.div
          initial={{ y: '-100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 80,
            damping: 25,
            duration: 1.5,
          }}
        >
          <Navigation value={true} />
        </motion.div>
      </div>
      <div className="w-full max-w-md bg-gray-300 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
          Forgot Password
        </h2>
        <form onSubmit={handleForgotPassword} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Enter your email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 bg-gray-200 py-2 mt-1 text-gray-900 border rounded-md focus:ring-green-500 focus:border-green-500"
              placeholder="Enter your email"
              required
            />
          </div>
          {successMessage && (
            <p className="text-green-500 text-sm">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-red-500 text-sm">{errorMessage}</p>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full bg-blue-700 font-semibold text-white py-2 px-4 hover:shadow-lg transition duration-300 transform hover:scale-105 rounded-md ${
              isLoading ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-500'
            }`}
          >
            {isLoading ? 'Sending...' : 'Send Magic Link'}
          </button>
        </form>
      </div>
    </div>
  );
}
