import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  FaArrowRight,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitter,
} from 'react-icons/fa';
import logo from '../assets/logo1.png';

const Footer = () => {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsRes, servicesRes] = await Promise.all([
          axios.get('/api/product'),
          axios.get('/api/service'),
        ]);
        setProducts(productsRes.data);
        setServices(servicesRes.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubscribe = (e) => {
    e.preventDefault();
    // Handle subscription logic here
    console.log('Subscribed with:', email);
    setEmail('');
  };

  return (
    <footer className="bg-gray-900 text-white pt-16 pb-8">
      <div className="container mx-auto px-4">
        <div className="flex md:flex-row flex-col justify-between gap-10 mb-12">
          {/* Company Info with Logo */}
          <div className="space-y-6 md:w-[25%]">
            <div className="flex items-center">
              {/* Placeholder for your logo - replace with your actual logo */}
              <div className=" w-20 h-12 rounded-lg flex items-center justify-center mr-3">
                <img src={logo} alt="" />
              </div>
              <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                BARATON GROUP CO.
              </h2>
            </div>
            <p className="text-gray-300">
              Founded in 2007, BARATON Group started with importing Tata-Daewoo
              trucks spare parts and has grown into a diversified importer and
              distributor.
            </p>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaFacebook size={20} />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaTwitter size={20} />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaLinkedin size={20} />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaInstagram size={20} />
              </a>
            </div>
          </div>
          <div className="flex md:w-[40%] justify-between">
            {/* Quick Links */}
            <div className="space-y-6">
              <h3 className="text-xl font-bold text-white">Quick Links</h3>
              <ul className="space-y-3">
                <li>
                  <a
                    href="/about"
                    className="flex items-center text-gray-300 hover:text-white transition-colors"
                  >
                    <FaArrowRight className="mr-2 text-blue-400" size={12} />
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="/products"
                    className="flex items-center text-gray-300 hover:text-white transition-colors"
                  >
                    <FaArrowRight className="mr-2 text-blue-400" size={12} />
                    Our Products
                  </a>
                </li>
                <li>
                  <a
                    href="/service"
                    className="flex items-center text-gray-300 hover:text-white transition-colors"
                  >
                    <FaArrowRight className="mr-2 text-blue-400" size={12} />
                    Our Services
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className="flex items-center text-gray-300 hover:text-white transition-colors"
                  >
                    <FaArrowRight className="mr-2 text-blue-400" size={12} />
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            {/* Products */}
            <div className="space-y-6">
              <h3 className="text-xl font-bold text-white">Our Products</h3>
              <ul className="space-y-3">
                {products.slice(0, 5).map((product) => (
                  <li key={product._id}>
                    <a
                      href={`/product/${product._id}`}
                      className="flex items-center text-gray-300 hover:text-white transition-colors"
                    >
                      <FaArrowRight className="mr-2 text-blue-400" size={12} />
                      {product.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Contact Info */}
          <div className="space-y-6">
            <h3 className="text-xl font-bold text-white">Contact Us</h3>
            <div className="space-y-4">
              <div className="flex items-start">
                <FaMapMarkerAlt className="mt-1 mr-3 text-blue-400" />
                <div>
                  <p className="text-gray-300">Bole Road, Wollo Sefer</p>
                  <p className="text-gray-300">Addis Ababa, Ethiopia</p>
                  <p className="text-gray-300">P.O.Box 62716</p>
                </div>
              </div>
              <div className="flex items-center">
                <FaPhone className="mr-3 text-blue-400" />
                <div className="space-y-1">
                  <a
                    href="tel:+251911514558"
                    className="block text-gray-300 hover:text-white"
                  >
                    +251 911 514558
                  </a>
                  <a
                    href="tel:+251911175644"
                    className="block text-gray-300 hover:text-white"
                  >
                    +251 911 175644
                  </a>
                  <a
                    href="tel:+251114709133"
                    className="block text-gray-300 hover:text-white"
                  >
                    +251 114 709133
                  </a>
                </div>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="mr-3 text-blue-400" />
                <a
                  href="mailto:aboma.taye@gmail.com"
                  className="text-gray-300 hover:text-white"
                >
                  aboma.taye@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 pt-8 flex flex-col  justify-between items-center">
          <p className="text-gray-400 text-sm mb-4">
            &copy; {new Date().getFullYear()} Baraton Group Co. All rights
            reserved.
          </p>
          <div className="flex items-center">
            <p className="text-gray-400 text-sm mr-2">Developed by</p>
            <a
              href="https://xyberlabs.et"
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 hover:from-blue-300 hover:to-purple-500 transition-colors"
            >
              xyberlabs
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
