import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaCopy, FaUpload } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Adminnav from './AdminNav';

const EditProducts = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [image, setImage] = useState(null);
  const [country, setCountry] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/product/${id}`);
        setTitle(response.data.title);
        setShortDescription(response.data.shortDescription);
        setCountry(response.data.country);
        setContent(response.data.content);
        setCurrentImage(response.data.imageUrl);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Failed to load product details');
      }
    };
    fetchProduct();
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('Please select an image.');
      return;
    }
    if (!file.type.startsWith('image/')) {
      setError('Please upload a valid image file.');
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      setError('Image size must be less than 5MB.');
      return;
    }
    setImage(file);
    setError('');
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post('/api/product/postPic', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setImageUrl(response.data.image);
      setError('');
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !shortDescription || !content || !country) {
      setError('Please fill all the required fields');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('shortDescription', shortDescription);
    formData.append('content', content);
    formData.append('country', country);
    formData.append('id', id);

    if (image) {
      formData.append('image', image);
    }

    try {
      await axios.put(`/api/product/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setError('');
      toast.success('Product updated successfully');
      navigate('/admin');
    } catch (error) {
      console.error('Error updating product:', error);
      setError(
        error.response?.data?.message ||
          'Failed to update the product. Please try again.'
      );
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(imageUrl)
      .then(() => {
        toast.success('Image URL copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 font-roboto flex flex-col">
      <Adminnav />

      {/* Main Content */}
      <div className="flex-1 flex md:ml-[255px] flex-col">
        {/* Title Section */}
        <motion.div
          className="w-full bg-gray-900 flex justify-between items-center p-3 pl-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h1 className="text-2xl  pl-5  sm:text-3xl py-2 font-semibold text-white ">
            Edit Products
          </h1>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/admin"
              className="flex items-center text-indigo-600 hover:text-indigo-800 transition"
            >
              <FaArrowLeft className="mr-2" />
              Back to admin
            </Link>
          </motion.div>
        </motion.div>

        {/* Product Form Container */}
        <motion.div
          className="w-full min-h-[91vh]  bg-white shadow-xl overflow-hidden"
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col lg:flex-row">
            {/* Product Info Section */}
            <motion.div
              className="w-full lg:w-2/5 bg-gray-800 min-h-[91vh]  p-8 sm:p-10 lg:p-12 text-white"
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold mb-6">
                Product Details
              </h2>
              <p className="text-indigo-100 mb-8 text-lg">
                Update basic information about your product
              </p>

              {error && (
                <motion.div
                  className="bg-red-50 border-l-4 border-red-500 p-4 mb-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <p className="text-red-700">{error}</p>
                </motion.div>
              )}

              <div className="space-y-6">
                {/* Product Name */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Product Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter product name"
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white placeholder-gray-300 transition"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </motion.div>

                {/* Country */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Country
                  </label>
                  <input
                    type="text"
                    placeholder="Enter product country"
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white placeholder-gray-300 transition"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </motion.div>

                {/* Short Description */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Short Description
                  </label>
                  <textarea
                    placeholder="Brief description of the product"
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white placeholder-gray-300 transition h-32"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    required
                  />
                </motion.div>

                {/* Image Upload */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Product Image (Optional)
                  </label>
                  <div className="relative">
                    <input
                      type="file"
                      className="w-full opacity-0 absolute inset-0 cursor-pointer"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    <div className="w-full px-4 py-3 bg-gray-700 border border-gray-600 flex items-center justify-between cursor-pointer hover:bg-gray-600 transition">
                      <span className="text-gray-300">
                        {image ? image.name : 'Select an image (PNG, JPG)'}
                      </span>
                      <FaUpload className="text-indigo-300" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-400 mt-1">
                    Max file size: 5MB
                  </p>
                </motion.div>

                {/* Current Image Preview */}
                {currentImage && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.7 }}
                  >
                    <label className="block text-sm font-medium text-indigo-100 mb-2">
                      Current Image
                    </label>
                    <div className="border border-gray-600 rounded-lg overflow-hidden">
                      <img
                        src={currentImage}
                        alt="Current product"
                        className="w-full h-auto"
                      />
                    </div>
                  </motion.div>
                )}
              </div>
            </motion.div>

            {/* Content Section */}
            <motion.div
              className="w-full lg:w-3/5 p-8 sm:p-10 lg:p-12"
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6">
                Product Content
              </h2>

              <div className="space-y-6">
                {/* Markdown Editor */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  data-color-mode="light"
                >
                  <MDEditor
                    value={content}
                    onChange={setContent}
                    height={400}
                    visibleDragbar={false}
                    previewOptions={{ className: 'md-editor-light' }}
                  />
                </motion.div>

                {/* Additional Image Upload */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Upload Additional Image
                  </label>
                  <div className="relative">
                    <input
                      type="file"
                      className="w-full opacity-0 absolute inset-0 cursor-pointer"
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    <div className="w-full px-4 py-3 border border-gray-300 flex items-center justify-between cursor-pointer hover:bg-gray-50 transition">
                      <span className="text-gray-700">
                        {loading ? 'Uploading...' : 'Select an image'}
                      </span>
                      <FaUpload className="text-gray-500" />
                    </div>
                  </div>
                </motion.div>

                {/* Image URL Display */}
                {imageUrl && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                    className="space-y-2"
                  >
                    <label className="block text-sm font-medium text-gray-700">
                      Image URL
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={imageUrl}
                        readOnly
                        className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                      />
                      <motion.button
                        type="button"
                        onClick={copyToClipboard}
                        className="px-4 py-2 bg-gray-800 text-white rounded-lg flex items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <FaCopy className="mr-2" />
                        Copy
                      </motion.button>
                    </div>
                  </motion.div>
                )}

                {/* Submit Button */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                  className="pt-4"
                >
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-6 transition flex items-center justify-center"
                  >
                    {loading ? (
                      <span className="flex items-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Updating Product...
                      </span>
                    ) : (
                      'Update Product'
                    )}
                  </button>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default EditProducts;
