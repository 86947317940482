import React, { useState } from "react";
import { motion } from "framer-motion";
import { Element } from "react-scroll";
import { FaTools, FaBuilding, FaChartLine, FaGlobe } from "react-icons/fa";
import sol from '../assets/sol.png';

const BaratonTradeLLC = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Element name="baraton-trade-llc">
            <div className="max-w-full bg-gray-800 min-h-[60vh] mb-10 overflow-y-auto overflow-x-hidden lg:mx-32 px-4 sm:px-6 py-4 sm:py-16 lg:px-8 shadow-lg">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white text-center">BARATON TRADE LLC</h2>
                <div className={`mt-8 sm:mt-12 flex flex-col-reverse lg:flex-row gap-6 sm:gap-10 justify-center w-full px-2 sm:px-4 lg:px-20 items-center`}>
                    <motion.div
                        className={`space-y-4 sm:space-y-6 ${!isExpanded ? 'w-full lg:w-[65%]' : 'w-full'}`}
                        initial={{ x: '-100%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}
                    >
                        <div className="flex flex-col lg:flex-row items-center gap-4 sm:gap-6 justify-between">
                            <div>
                                <p className="text-base sm:text-xl lg:text-xl text-white">
                                    A subsidiary of Baraton Group, Baraton Trade LLC operates globally, offering
                                    innovative and reliable product solutions. Licensed in Florida, USA, the company
                                    prioritizes exceptional customer service and strong relationships.
                                </p>
                                <p className="pt-2 sm:pt-4 text-base sm:text-xl lg:text-xl text-white">
                                    With a focus on quality and integrity, Baraton Trade LLC ensures clients receive top-notch support while driving industry advancements through continuous innovation.
                                </p>
                            </div>

                            <img
                                src={sol}
                                alt="Baraton Trade PLC"
                                className={`rounded-xl w-full max-w-[300px] sm:w-[400px] ${!isExpanded && 'hidden'} shadow-md`}
                            />
                        </div>

                        {/* Expanded Content */}
                        <div className={`${isExpanded ? 'block' : 'hidden'}`}>
                            <h3 className="text-xl sm:text-2xl font-bold pb-2 sm:pb-3 text-white pt-3 sm:pt-5 flex items-center">
                                <FaTools className="mr-2" /> Core Business Areas:
                            </h3>
                            <ul className="list-disc list-inside pb-4 sm:pb-6 text-sm sm:text-base lg:text-lg text-white space-y-1 sm:space-y-2">
                                <li>
                                    <strong>Technological Solutions</strong> – Researching and marketing advanced solutions for the construction industry.
                                </li>
                                <li>
                                    <strong>Real Estate Development & Portfolio Management</strong> – Managing diverse properties across multiple markets.
                                </li>
                                <li>
                                    <strong>Stock & Share Portfolio Management</strong> – Strategically buying and selling assets to outperform market trends.
                                </li>
                                <li>
                                    <strong>Global Product Distribution</strong> – Partnering with trusted distributors to expand market reach worldwide.
                                </li>
                            </ul>

                            <h3 className="text-xl sm:text-2xl font-bold text-white pb-2 sm:pb-4 pt-3 sm:pt-5 flex items-center">
                                <FaBuilding className="mr-2" /> Industry Leadership & Market Presence
                            </h3>
                            <p className="text-base sm:text-xl text-white">
                                Our brands are recognized for their exceptional performance, cost efficiency, quality, and safety standards. Baraton Group provides comprehensive solutions for:
                            </p>
                            <ul className="list-disc list-inside text-sm sm:text-base lg:text-xl py-2 sm:py-3 pb-4 sm:pb-6 text-white space-y-1 sm:space-y-2">
                                <li>Construction machinery</li>
                                <li>Rock drilling tools</li>
                                <li>Hydraulic attachments</li>
                                <li>Equipment servicing & support</li>
                            </ul>

                            <h3 className="text-xl sm:text-2xl font-bold text-white pb-2 sm:pb-4 pt-3 sm:pt-5 flex items-center">
                                <FaChartLine className="mr-2" /> Exclusive Sales & Marketing Rights
                            </h3>
                            <p className="text-base sm:text-xl lg:text-2xl text-white">
                                Baraton Trade LLC holds exclusive sales and marketing rights for:
                            </p>
                            <ul className="list-disc list-inside text-sm sm:text-base lg:text-xl pt-2 sm:pt-3 pb-4 sm:pb-6 text-white space-y-1 sm:space-y-2">
                                <li>Furukawa Rock Drill Co. Ltd – Japan, Ethiopia</li>
                                <li>Airman Industries Ltd – Japan, Ethiopia</li>
                            </ul>

                            <h3 className="text-xl sm:text-2xl font-bold pb-3 sm:pb-5 pt-3 sm:pt-5 text-white flex items-center">
                                <FaGlobe className="mr-2" /> Expanding Global Investments
                            </h3>
                            <ul className="list-disc list-inside text-sm sm:text-base lg:text-xl text-white space-y-1 sm:space-y-2">
                                <li>
                                    <strong>Real Estate Portfolio</strong> – Spanning three countries, with a commitment to innovation and excellence in construction.
                                </li>
                                <li>
                                    <strong>Stock & Share Management</strong> – Maximizing returns through strategic investments.
                                </li>
                            </ul>
                        </div>

                        {/* Read More Button */}
                        <button
                            onClick={toggleReadMore}
                            className="text-white text-base sm:text-lg lg:text-xl font-semibold underline hover:text-red-500 transition-colors"
                        >
                            {isExpanded ? "Read Less" : "Read More"}
                        </button>
                    </motion.div>

                    <motion.div
                        initial={{ x: '100%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}
                        className={`${isExpanded ? 'hidden' : 'block'} w-full lg:w-auto`}
                    >
                        <img
                            src={sol}
                            alt="Baraton Trade LLC"
                            className="rounded-xl object-cover w-full max-w-[300px] mx-auto h-[200px] sm:h-[250px] lg:h-[350px] shadow-md"
                        />
                    </motion.div>
                </div>
            </div>
        </Element>
    );
};

export default BaratonTradeLLC;