import { create } from 'zustand';

const useUser = create((set) => ({
  user: JSON.parse(localStorage.getItem('user')) || null,
  setUser: (user) => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
    set({ user });
  },
  service: JSON.parse(localStorage.getItem('service')) || null,
  setService: (service) => {
    if (service) {
      localStorage.setItem('service', JSON.stringify(service));
    } else {
      localStorage.removeItem('service');
    }
    set({ service });
  },
  product: JSON.parse(localStorage.getItem('product')) || null,
  setProduct: (product) => {
    if (product) {
      localStorage.setItem('product', JSON.stringify(product));
    } else {
      localStorage.removeItem('product');
    }
    set({ product });
  },
}));

export default useUser;
