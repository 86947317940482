import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus, FaTrash, FaUpload } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Adminnav from './AdminNav';

const EditService = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [contentList, setContentList] = useState([]);
  const [newContent, setNewContent] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await axios.get(`/api/service/${id}`);
        setTitle(response.data.title);
        setShortDescription(response.data.shortDescription);
        setContentList(response.data.contentList);
      } catch (error) {
        console.error(error);
        setError('Failed to fetch service details');
      }
    };
    fetchService();
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('Please select an image.');
      return;
    }
    if (!file.type.startsWith('image/')) {
      setError('Please upload a valid image file.');
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      // 5MB limit
      setError('Image size must be less than 5MB.');
      return;
    }
    setImage(file);
    setError('');
  };

  const handleAddContent = () => {
    if (newContent.trim() === '') {
      setError('Content description cannot be empty.');
      return;
    }
    setContentList([...contentList, newContent]);
    setNewContent('');
    setError('');
  };

  const handleRemoveContent = (index) => {
    const updatedContentList = contentList.filter((_, i) => i !== index);
    setContentList(updatedContentList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!title || !shortDescription || contentList.length === 0) {
      setError(
        'Please fill all the fields and add at least one content description.'
      );
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('shortDescription', shortDescription);
    if (image) {
      formData.append('image', image);
    }
    contentList.forEach((content, index) => {
      formData.append(`contentList[${index}]`, content);
    });

    try {
      await axios.put(`/api/service/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setError('');
      toast.success('Service updated successfully');
      navigate('/admin');
    } catch (error) {
      console.error('Error updating service:', error);
      setError('Failed to update the service. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 font-roboto flex flex-col">
      <Adminnav />

      <div className="flex-1 flex md:ml-[255px] flex-col">
        {/* Title Section */}
        <motion.div
          className="w-full bg-gray-900 flex justify-between items-center p-3 pl-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h1 className="text-2xl  pl-5  sm:text-3xl py-2 font-semibold text-white ">
            Edit Services
          </h1>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/admin/services"
              className="flex items-center text-indigo-600 hover:text-indigo-800 transition"
            >
              <FaArrowLeft className="mr-2" />
              Back to Services
            </Link>
          </motion.div>
        </motion.div>
        {/*
                <motion.div
                    className="w-full max-w-6xl mb-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                >
                    <div className="flex justify-between items-center">
                        <div>
                            <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4">
                                Edit Service
                            </h1>
                            <p className="text-lg sm:text-xl text-gray-600 max-w-2xl">
                                Update the service details below
                            </p>
                        </div>

                    </div>
                </motion.div> */}

        {/* Service Form Container */}
        <motion.div
          className="w-full bg-white shadow-xl overflow-hidden"
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col lg:flex-row">
            {/* Service Info Section */}
            <motion.div
              className="w-full lg:w-2/5 bg-gray-800 p-8 sm:p-10 lg:p-12 text-white"
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold mb-6">
                Service Details
              </h2>
              <p className="text-indigo-100 mb-8 text-lg">
                Update basic information about your service
              </p>

              {error && (
                <motion.div
                  className="bg-red-50 border-l-4 border-red-500 p-4 mb-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <p className="text-red-700">{error}</p>
                </motion.div>
              )}

              <div className="space-y-6">
                {/* Service Name */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Service Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter service name"
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white placeholder-gray-300 transition"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </motion.div>

                {/* Short Description */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Short Description
                  </label>
                  <textarea
                    placeholder="Brief description of the service"
                    className="w-full px-4 py-3 bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-white placeholder-gray-300 transition h-32"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    required
                  />
                </motion.div>

                {/* Image Upload */}
                <motion.div
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                >
                  <label className="block text-sm font-medium text-indigo-100 mb-2">
                    Service Image (Optional)
                  </label>
                  <div className="relative">
                    <input
                      type="file"
                      className="w-full opacity-0 absolute inset-0 cursor-pointer"
                      onChange={handleFileChange}
                    />
                    <div className="w-full px-4 py-3 bg-gray-700 border border-gray-600 flex items-center justify-between cursor-pointer hover:bg-gray-600 transition">
                      <span className="text-gray-300">
                        {image ? image.name : 'Select an image (PNG, JPG)'}
                      </span>
                      <FaUpload className="text-indigo-300" />
                    </div>
                  </div>
                  <p className="text-xs text-gray-400 mt-1">
                    Max file size: 5MB
                  </p>
                </motion.div>
              </div>
            </motion.div>

            {/* Content List Section */}
            <motion.div
              className="w-full lg:w-3/5 p-8 sm:p-10 lg:p-12"
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6">
                Service Features
              </h2>

              <div className="space-y-6">
                {/* Add New Content */}
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Add Service Feature
                  </label>
                  <div className="flex space-x-2">
                    <textarea
                      placeholder="Describe a feature of your service"
                      className="flex-1 px-4 py-3 border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                      value={newContent}
                      onChange={(e) => setNewContent(e.target.value)}
                      rows={3}
                    />
                    <motion.button
                      type="button"
                      onClick={handleAddContent}
                      className="px-4 py-3 bg-gray-800 text-white transition flex items-center"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaPlus className="mr-2" />
                      Add
                    </motion.button>
                  </div>
                </motion.div>

                {/* Content List */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="space-y-4"
                >
                  {contentList.map((content, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="flex items-center justify-between p-4 border border-gray-200 bg-gray-50 hover:bg-gray-100 transition"
                    >
                      <p className="text-gray-700 flex-1">{content}</p>
                      <button
                        type="button"
                        onClick={() => handleRemoveContent(index)}
                        className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50 transition"
                        aria-label="Remove feature"
                      >
                        <FaTrash />
                      </button>
                    </motion.div>
                  ))}
                </motion.div>

                {/* Submit Button */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="pt-4"
                >
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="w-full bg-gray-800 hover:bg-gray-700 text-white font-semibold py-3 px-6 transition flex items-center justify-center"
                  >
                    {loading ? (
                      <span className="flex items-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Updating Service...
                      </span>
                    ) : (
                      'Update Service'
                    )}
                  </button>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default EditService;
