import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { FaEdit, FaTimes, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ServiceAdminCard({ item, fetch }) {
  const [service] = useState(item);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/service/${item._id}`);
      toast.success('Service deleted successfully!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: 'bg-white text-gray-800 shadow-xl border border-gray-200',
      });
      setModalOpen(false);
      fetch();
    } catch (error) {
      console.error('Error deleting service:', error);
      toast.error('Failed to delete service', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        className: 'bg-white text-red-600 shadow-xl border border-gray-200',
      });
    }
  };

  return (
    <motion.div
      className="bg-white  shadow-md overflow-hidden border border-gray-200"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.3 }}
    >
      {/* Service Image with Edit Button */}
      <div className="relative h-48 overflow-hidden">
        <motion.img
          src={service.image}
          alt={service.title}
          className="w-full h-full bg-gray-900 object-scale-down"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.5 }}
        />

        <motion.button
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/edit/service/${service._id}`);
          }}
          className="absolute top-3 right-3 bg-white bg-opacity-90 hover:bg-opacity-100 text-indigo-600 p-2 rounded-full shadow-sm"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          title="Edit service"
        >
          <FaEdit className="text-lg" />
        </motion.button>
      </div>

      {/* Service Info */}
      <div className="p-5">
        <div className="flex justify-between items-start mb-3">
          <h2 className="text-xl font-bold text-gray-800">{service.title}</h2>
        </div>

        <p className="text-gray-600 text-sm mb-4 line-clamp-2">
          {service.shortDescription}
        </p>

        {service.contentList?.length > 0 && (
          <ul className="space-y-1 mb-4">
            {service.contentList.slice(0, 3).map((content, index) => (
              <li key={index} className="flex items-start">
                <span className="text-indigo-500 mr-2">•</span>
                <span className="text-gray-600 text-sm">{content}</span>
              </li>
            ))}
            {service.contentList.length > 3 && (
              <li className="text-gray-400 text-sm">
                + {service.contentList.length - 3} more
              </li>
            )}
          </ul>
        )}

        <motion.button
          onClick={() => setModalOpen(true)}
          className="w-full flex items-center justify-center gap-2 bg-gray-800 hover:bg-gray-700 text-white px-4 py-2  transition-colors"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <FaTrash className="text-sm" />
          <span>Delete Service</span>
        </motion.button>
      </div>

      {/* Delete Confirmation Modal */}
      {modalOpen && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50 backdrop-blur-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white rounded-2xl p-6 w-full max-w-md border border-gray-200 shadow-2xl"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: 'spring', stiffness: 300, damping: 25 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-800">
                Confirm Deletion
              </h2>
              <motion.button
                onClick={() => setModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaTimes />
              </motion.button>
            </div>

            <p className="text-gray-600 mb-6">
              Are you sure you want to delete{' '}
              <span className="font-semibold">"{service.title}"</span>? This
              action cannot be undone.
            </p>

            <div className="flex justify-end gap-4">
              <motion.button
                onClick={() => setModalOpen(false)}
                className="px-6 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800  border border-gray-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Cancel
              </motion.button>
              <motion.button
                onClick={handleDelete}
                className="px-6 py-2 bg-gray-800 hover:bg-gray-700 text-white  flex items-center gap-2"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaTrash />
                Delete
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
}
