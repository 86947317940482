import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/logo1.png';
import { FaBars, FaChevronDown, FaPhone, FaTimes } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import SearchBar from './SearchBar';
import useUser from '../zustand/useUser';
import UseFetchProductsAndServices from '../hooks/fetchProductAndService';
import searchProducts from '../hooks/searchProducts';

export default function Navigation({ value }) {
    const location = useLocation();
    const [showProducts, setShowProducts] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const { product } = useUser()
    const [searchedProducts, setSearchedProducts] = useState([])
    const dropdownRef = useRef(null);
    const mobileMenuRef = useRef(null);
    const navigate = useNavigate();


    UseFetchProductsAndServices()

    const isActiveLink = (path, mobile = false) => {
        if (mobile) {
            return location.pathname === path ? 'text-white border-l-[3px] bg-gradient-to-r from-gray-800 to-gray-900  border-white py-0' : '';
        }
        return location.pathname === path ? 'text-black border-b-[3px] border-black py-0' : '';

    };
    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowProducts(false);
            }
            if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
                setShowMobileMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Prevent scrolling when mobile menu is open
    useEffect(() => {
        if (showMobileMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [showMobileMenu]);



    // Sidebar animation variants
    const sidebarVariants = {
        hidden: { x: '100%', opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 30,
                duration: 0.5
            }
        },
        exit: {
            x: '100%',
            opacity: 0,
            transition: {
                ease: 'easeInOut',
                duration: 0.3
            }
        }
    };

    // Overlay animation variants
    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };

    // Dropdown animation variants
    const dropdownVariants = {
        hidden: { height: 0, opacity: 0 },
        visible: {
            height: 'auto',
            opacity: 1,
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        },
        exit: {
            height: 0,
            opacity: 0,
            transition: {
                duration: 0.2,
                ease: 'easeInOut'
            }
        }
    };

    return (
        <>
            {/* Main Navigation Bar */}
            <div className={`text-black flex flex-col z-50 font-african  rounded-xl justify-between  py-3 shadow-sm w-full`}>

                <div className='flex justify-between w-full px-4 border-b items-center md:px-20 border-gray-500 pb-2'>
                    <div className='flex rounded-xl p-4 py-1  gap-2 items-end'>
                        <img src={logo} onClick={() => navigate('/')} className='w-[100px] hover:cursor-pointer md:w-[250px]' alt="Company Logo" />
                    </div>



                    <div className='flex gap-4'>
                        <SearchBar />

                        <a href="tel:+251911209797" className={`bg-gray-800 text-xl whitespace-nowrap md:flex hidden items-center gap-4  text-white px-3 py-2  hover:bg-opacity-70 transition-colors`}>
                            Let's Talk
                            <FaPhone />
                        </a>
                        <button
                            className='md:hidden flex text-2xl p-2'
                            onClick={() => setShowMobileMenu(true)}
                            aria-label="Open menu"
                        >
                            <FaBars />
                        </button>
                    </div>
                </div>

                {/* Desktop Navigation */}
                <div className='md:flex hidden px-24 py-3 text-xl gap-7'>
                    <Link to={'/'} className={`${isActiveLink('/')}`}>Home</Link>
                    <Link to={'/about'} className={`${isActiveLink('/about')}`}>About Us</Link>

                    {/* Products Dropdown */}
                    <div className="relative" ref={dropdownRef}>
                        <div
                            onClick={() => navigate('/products')}
                            className="flex gap-1 items-center cursor-pointer"
                        >
                            <span className={isActiveLink('/products')}>Products</span>
                            <FaChevronDown
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowProducts(!showProducts);
                                }}
                                className="transition-transform duration-200"
                            />
                        </div>
                        {showProducts && (
                            <motion.div
                                className="absolute top-full left-0 bg-white text-gray-800  shadow-lg mt-2 w-[500px] z-50"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={dropdownVariants}
                            >
                                <div className="grid grid-cols-3 gap-2 p-4">
                                    {product?.map((p) => (
                                        <Link
                                            key={p._id}
                                            to={`/product/${p._id}`}
                                            className="block px-4 py-2 text-lg hover:bg-gray-100 transition-colors duration-200"
                                            onClick={() => setShowProducts(false)}
                                        >
                                            {p.title}
                                        </Link>
                                    ))}
                                </div>
                            </motion.div>
                        )}
                    </div>

                    <Link to={'/service'} className={`${isActiveLink('/service')}`}>Service</Link>
                    <Link to={'/contact'} className={`${isActiveLink('/contactMe')}`}>Contact Us</Link>
                </div>

                {/* Mobile Menu Button */}

            </div>

            {/* Mobile Sidebar Menu with Animation */}
            <AnimatePresence>
                {showMobileMenu && (
                    <div className="fixed inset-0 z-50 overflow-hidden">
                        {/* Animated Overlay */}
                        <motion.div
                            className="absolute inset-0 bg-black bg-opacity-50"
                            onClick={() => setShowMobileMenu(false)}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={overlayVariants}
                        ></motion.div>

                        {/* Animated Sidebar Content */}
                        <motion.div
                            ref={mobileMenuRef}
                            className="absolute right-0 top-0 h-full w-3/5 max-w-sm bg-gray-900 shadow-xl"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={sidebarVariants}
                        >
                            <div className="flex flex-col h-full p-4">
                                {/* Header */}
                                <div className="flex justify-between items-center border-b border-white/20 pb-4">
                                    <img src={logo} onClick={() => navigate('/')} className='w-[120px] hover:cursor-pointer' alt="Company Logo" />
                                    <button
                                        onClick={() => setShowMobileMenu(false)}
                                        className="text-white text-2xl p-2 hover:text-gray-200 transition-colors"
                                        aria-label="Close menu"
                                    >
                                        <FaTimes />
                                    </button>
                                </div>

                                {/* Navigation Links */}
                                <nav className="flex-1 text-white overflow-y-auto py-6">
                                    <ul className="space-y-4">
                                        <li>
                                            <Link
                                                to={'/'}
                                                className={`block text-xl py-3 px-4 hover:bg-[#4a85b2]   transition-colors ${isActiveLink('/', true)}`}
                                                onClick={() => setShowMobileMenu(false)}
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={'/about'}
                                                className={`block text-xl py-3 px-4 hover:bg-[#4a85b2]  transition-colors ${isActiveLink('/about', true)}`}
                                                onClick={() => setShowMobileMenu(false)}
                                            >
                                                About Us
                                            </Link>
                                        </li>

                                        {/* Mobile Products Dropdown */}
                                        <li>
                                            <div className="relative">
                                                <div
                                                    onClick={() => {
                                                        navigate('/products');
                                                        setShowMobileMenu(false);
                                                    }}
                                                    className={`flex justify-between items-center text-xl py-3 px-4 hover:bg-[#4a85b2]  transition-colors ${isActiveLink('/products', true)}`}
                                                >
                                                    <span>Products</span>
                                                    <FaChevronDown
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setShowProducts(!showProducts);
                                                        }}
                                                        className={`transition-transform ${showProducts ? 'transform rotate-180' : ''}`}
                                                    />
                                                </div>
                                                <AnimatePresence>
                                                    {showProducts && (
                                                        <motion.div
                                                            className="ml-6 mt-2 bg-[#4a85b2]  overflow-hidden"
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                            variants={dropdownVariants}
                                                        >
                                                            {product?.map((p) => (
                                                                <Link
                                                                    key={p._id}
                                                                    to={`/product/${p._id}`}
                                                                    className="block py-2 px-4 text-lg hover:bg-[#5a95c2] transition-colors"
                                                                    onClick={() => {
                                                                        setShowMobileMenu(false);
                                                                        setShowProducts(false);
                                                                    }}
                                                                >
                                                                    {p.title}
                                                                </Link>
                                                            ))}
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                        </li>

                                        <li>
                                            <Link
                                                to={'/service'}
                                                className={`block text-xl py-3 px-4 hover:bg-[#4a85b2]  transition-colors ${isActiveLink('/service', true)}`}
                                                onClick={() => setShowMobileMenu(false)}
                                            >
                                                Service
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={'/contact'}
                                                className={`block text-xl py-3 px-4 hover:bg-[#4a85b2]  transition-colors ${isActiveLink('/contactMe', true)}`}
                                                onClick={() => setShowMobileMenu(false)}
                                            >
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>

                                {/* Footer/Call to Action */}
                                <div className="border-t border-white/20 pt-4">
                                    <Link
                                        to={'/contactMe'}
                                        className="block bg-red-500 text-xl text-center text-white px-4 py-3  hover:bg-red-600 transition-colors"
                                        onClick={() => setShowMobileMenu(false)}
                                    >
                                        Let's Talk
                                    </Link>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </>
    );
}