import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Element } from "react-scroll";
import { FaRegSmileBeam, FaHandshake, FaSyncAlt, FaBookOpen, FaHandsHelping, FaRocket } from "react-icons/fa";
import Navigation from "../component/Navigation";
import bg from '../assets/background.png';
import ceo from '../assets/ceo.png';
import BaratonTradeLLC from "../component/BartonTLC";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import BaratonTradePLC from "../component/BartonTrande";
import Footer from "../component/Footer";
// Animation variants
const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const containerVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.3,
        },
    },
};

const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 80, damping: 15 },
    },
};

const AboutUs = () => {
    const [selected, setSelected] = useState('ceo-message');
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='min-h-[86vh]  overflow-x-hidden w-[100%] bg-[#F8F9FA] font-african'>
            <div
                className="min-h-[86vh] p-2 text-white flex flex-col items-center "

            >


                <div className=" w-full">
                    <motion.div
                        initial={{ y: '-100%', opacity: 0 }} // Start off-screen above
                        animate={{ y: 0, opacity: 1 }}        // End at the original position
                        transition={{
                            type: 'spring',                       // Smooth spring animation
                            stiffness: 80,                        // Snappy start
                            damping: 25,                          // Smooth stop
                            duration: 1.5,                        // Duration of animation
                        }}
                    >
                        <Navigation />
                    </motion.div>
                </div>
                <nav className="sticky top-0 py-2 lg:py-8">
                    <div className="max-w-7xl lg:text-xl text-sm mx-auto px-6 lg:px-8 py-0 flex justify-center space-x-8">
                        <div onClick={() => setSelected("ceo-message")} className={`text-black hover:text-gray-500 ${selected === 'ceo-message' ? 'border-b-[3px] border-black' : 'text-black'} cursor-pointer font-medium`}>CEO Message</div>
                        <div onClick={() => setSelected("philosophy")} className={`text-black hover:text-gray-500 ${selected === 'philosophy' ? 'border-b-[3px] border-black' : 'text-black'} cursor-pointer font-medium`}>Philosophy</div>
                        <div onClick={() => setSelected("baraton-trade-llc")} className={`text-black hover:text-gary-500 ${selected === 'baraton-trade-llc' ? 'border-b-[3px] border-black' : 'text-black'} cursor-pointer font-medium`}>Baraton Trade LLC</div>
                        <div onClick={() => setSelected("baraton-trade-plc")} className={`text-black hover:text-gray-500 ${selected === 'baraton-trade-plc' ? 'border-b-[3px] border-black' : 'text-black'} cursor-pointer font-medium`}>Baraton Trade PLC</div>
                    </div>
                </nav>

                {selected === 'ceo-message' && (
                    <Element name="ceo-message">
                        <div className="max-w-full bg-gray-800  px-2 py-4 lg:py-10">
                            <motion.div
                                className="text-center"
                                initial={{ x: '-100%', opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                viewport={{ once: true }}
                                transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}
                            >
                                <h2 className="text-2xl lg:text-5xl font-bold text-white ">CEO’s Message</h2>
                            </motion.div>
                            <div className="flex lg:flex-row  flex-col gap-4  lg:gap-10 items-center justify-center lg:px-32">
                                <motion.div
                                    initial={{ x: '-100%', opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    viewport={{ once: true }}
                                    transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5, delay: 0.5 }}
                                >
                                    <img src={ceo} className="h-[200px] lg:h-[470px] object-cover" alt="CEO" />
                                </motion.div>
                                <motion.div
                                    initial={{ x: '100%', opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    viewport={{ once: true }}
                                    transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5, delay: 0.5 }}
                                >
                                    <div className="lg:mt-8 lg:max-w-2xl w-full lg:mx-auto text-sm lg:text-lg text-white space-y-6 relative p-6">
                                        <FaQuoteLeft className="text-2xl text-white absolute top-8 -left-1 lg:-left-4" />
                                        <p>
                                            Since our company’s founding in 2007, we have been committed to fostering a prosperous society in Ethiopia by delivering efficient, user-friendly products to customers in the construction and mining industries. Our solutions play a vital role in the development of social infrastructure.
                                        </p>
                                        <p>
                                            We will continue to build on the strong foundation we have established, striving for leadership in our industry. By exploring new marketing opportunities and innovative ideas, we aim to provide original products and services that meet evolving needs. Our commitment extends to creating a sustainable society by working collaboratively with our suppliers and end users.
                                        </p>
                                        <FaQuoteRight className="text-2xl text-white absolute bottom-8 right-2" />
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </Element>
                )}

                {selected === 'philosophy' && (
                    <Element name="philosophy">
                        <div className="bg-gray-800 mb-10 lg:mx-32 px-10  lg:py-16 py-3 shadow-lg">
                            <div className="max-w-full flex lg:flex-row flex-col gap-4 py-6 ">
                                <div className="w-full lg:w-[45%]">
                                    <h2 className="text-3xl font-bold text-white ">Philosophy</h2>
                                    <motion.div className="mt-5 grid grid-cols-2  gap-2" variants={containerVariants} initial="hidden" animate="visible">
                                        {[
                                            { title: "Simplicity", description: "Making every experience seamless and effortless.", icon: <FaRegSmileBeam className="text-red-500 text-xl mr-2" /> },
                                            { title: "Trust", description: "Building strong, personalized relationships based on integrity.", icon: <FaHandshake className="text-red-500 text-xl mr-2" /> },
                                            { title: "Change", description: "Embracing change as a catalyst for continuous growth and innovation.", icon: <FaSyncAlt className="text-red-500 text-xl mr-2" /> },
                                            { title: "Expertise", description: "Delivering high-quality services and products through shared knowledge.", icon: <FaBookOpen className="text-red-500 text-xl mr-2" /> },
                                            { title: "Responsible", description: "Growing together with integrity and fairness while respecting people and the environment.", icon: <FaHandsHelping className="text-red-500 text-xl mr-2" /> },
                                            { title: "Speed", description: "Acting swiftly to achieve results—because speed is key.", icon: <FaRocket className="text-red-500 text-xl mr-2" /> },
                                        ].map((value, index) => (
                                            <motion.div key={index} variants={itemVariants} className="bg-gray-700  w-fit  items-center  lg:p-3 p-2 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
                                                <div className="flex items-center">
                                                    {value.icon}
                                                    <h3 className="text-xl font-semibold text-white">{value.title}: </h3>
                                                </div>
                                                <p className="mt-3 lg:ml-7 ml-3 text-sm text-white">{value.description}</p>
                                            </motion.div>
                                        ))}
                                    </motion.div>
                                </div>
                                <div className="w-full lg:w-[55%] ml-5">
                                    <div className="">
                                        <motion.div initial={{ x: '-100%', opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} viewport={{ once: true }} transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}>
                                            <h3 className="text-3xl font-bold text-white ">Mission</h3>
                                        </motion.div>
                                        <motion.div initial={{ x: '100%', opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} viewport={{ once: true }} transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5, delay: 0.5 }}>
                                            <ul className="list-disc list-inside mt-4 text-sm lg:text-lg text-white space-y-2">
                                                <li>Committed to excellence by leveraging our competitive advantages in every field we operate.</li>
                                                <li>Upholding the highest standards of business ethics and organizational values.</li>
                                                <li>Delivering customer satisfaction through exceptional products and services.</li>
                                                <li>Cultivating a culture of trust, openness, and mutual respect, making our workplace both stimulating and rewarding.</li>
                                            </ul>
                                        </motion.div>
                                    </div>
                                    <div className="mt-5">
                                        <motion.div initial={{ x: '-100%', opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} viewport={{ once: true }} transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}>
                                            <h3 className="text-3xl font-bold text-white">Vision</h3>
                                        </motion.div>
                                        <motion.div initial={{ x: '100%', opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} viewport={{ once: true }} transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5, delay: 0.5 }}>
                                            <p className="mt-4 text-sm lg:text-lg text-white">
                                                To be a regional leader in our industry, driven by sustainable growth, knowledge, excellence, and best practices. As we face the challenges ahead, we will stay agile, adapting to the evolving world while remaining a trusted partner for the companies that rely on us. Together, we shape the future!
                                            </p>
                                        </motion.div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Element>
                )}

                {selected === 'baraton-trade-plc' && (
                    <BaratonTradePLC />
                )}

                {selected === 'baraton-trade-llc' && (
                    <BaratonTradeLLC />
                )}
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;