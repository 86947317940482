import React, { useState } from "react";
import { motion } from "framer-motion";
import { Element } from "react-scroll";
import { FaChartLine, FaTools, FaWrench } from "react-icons/fa";
import img1 from '../assets/img1.jpg'
import img2 from '../assets/img2.jpg'


const BaratonTradePLC = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Element name="baraton-trade-plc">
            <div className="max-w-full lg:mx-32 bg-gray-800 min-h-[60vh] mb-10 overflow-y-auto overflow-x-hidden  px-4 sm:px-6 py-6 lg:py-16 lg:px-8">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white text-center">BARATON TRADE PLC</h2>
                <div className={`mt-8 sm:mt-12 grid grid-cols-1 ${!isExpanded ? 'md:grid-cols-2' : ''} lg:px-10 md:px-20 gap-6 sm:gap-10`}>
                    {/* Image Section */}
                    <motion.div
                        initial={{ x: '-100%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}
                        className={`${isExpanded ? 'hidden' : 'flex gap-2'}`}
                    >
                        <img
                            src={img1}
                            alt="Baraton Trade PLC"
                            className="rounded-xl w-[50%] h-auto   lg:max-w-[250px] shadow-md"
                        />
                        <img
                            src={img2}
                            alt="Baraton Trade PLC"
                            className="rounded-xl w-[50%] h-auto lg:max-w-[250px] shadow-md"
                        />
                    </motion.div>

                    {/* Text Section */}
                    <motion.div
                        className="space-y-4 sm:space-y-6"
                        initial={{ x: '100%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ type: 'spring', stiffness: 80, damping: 25, duration: 1.5 }}
                    >
                        <div className="flex flex-col lg:flex-row items-center gap-4 sm:gap-6 justify-between">
                            <img
                                src={img1}
                                alt="Baraton Trade PLC"
                                className={`rounded-xl w-full max-w-[400px] ${!isExpanded ? 'hidden' : 'block'} shadow-md`}
                            />

                            <div>
                                <p className="text-base sm:text-lg lg:text-xl text-white">
                                    A proud subsidiary of Baraton Group, Baraton Trade PLC is the official agent of Furukawa Rock Drills Co., Ltd and Airman Corporation Ltd in Ethiopia.
                                </p>
                                <p className="text-base sm:text-lg lg:text-xl mt-3 sm:mt-4 text-white">
                                    With the expertise of our consultants and certified engineers, we provide industry-leading solutions tailored to be efficient, reliable, and cost-effective.
                                </p>
                                <p className="text-base sm:text-lg lg:text-xl mt-3 sm:mt-4 text-white">

                                    Our authentic, high-quality parts are built to support the way you work – giving you 100% confidence in performance and durability.

                                </p>
                            </div>
                        </div>

                        {/* Expanded Content */}
                        <div className={`${isExpanded ? 'block' : 'hidden'}`}>
                            <p className="text-base sm:text-lg lg:text-xl text-white">
                                We recognize that fast access to the right parts, service, and support is critical to keeping your machines operating at peak performance, ensuring maximum productivity for your business.
                            </p>

                            <h3 className="text-xl sm:text-2xl font-bold flex items-center pt-3 sm:pt-4 pb-1 sm:pb-2 text-white mt-4 sm:mt-6">
                                <FaTools className="mr-2" /> Genuine Furukawa & Airman Parts
                            </h3>
                            <p className="text-base sm:text-lg lg:text-xl text-white">
                                Our authentic, high-quality parts are built to support the way you work – giving you 100% confidence in performance and durability.
                            </p>

                            <h3 className="text-xl sm:text-2xl font-bold flex items-center pt-3 sm:pt-4 pb-1 sm:pb-2 text-white mt-4 sm:mt-6">
                                <FaWrench className="mr-2" /> Comprehensive Support for Your Equipment
                            </h3>
                            <p className="text-base sm:text-lg lg:text-xl text-white">
                                We provide everything you need to keep your machines and attachments in top condition. Whether you're looking for:
                            </p>
                            <ul className="list-disc list-inside text-base sm:text-lg lg:text-xl text-white space-y-1 sm:space-y-2 pl-4">
                                <li>Genuine replacement parts</li>
                                <li>Technical support</li>
                                <li>Self-repair resources</li>
                            </ul>
                            <p className="text-base sm:text-lg lg:text-xl text-white">
                                We make it easy and efficient to keep your machines running smoothly.
                            </p>

                            <h3 className="text-xl sm:text-2xl font-bold flex items-center pt-3 sm:pt-4 pb-1 sm:pb-2 text-white mt-4 sm:mt-6">
                                <FaChartLine className="mr-2" /> Enhancing Productivity & Profitability
                            </h3>
                            <p className="text-base sm:text-lg lg:text-xl text-white">
                                Baraton Trade PLC delivers cutting-edge technology to help you stay connected on the job site—reducing downtime while maximizing productivity and profitability.
                            </p>
                        </div>

                        {/* Read More Button */}
                        <button
                            onClick={toggleReadMore}
                            className="text-white text-base sm:text-lg lg:text-xl font-semibold underline hover:text-red-500 transition-colors"
                        >
                            {isExpanded ? "Read Less" : "Read More"}
                        </button>
                    </motion.div>
                </div>
            </div>
        </Element>
    );
};

export default BaratonTradePLC;