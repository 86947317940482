import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CompanyAdminCard from '../../component/ComapnyAdminCard';
import ProductAdminCard from '../../component/ProductAdminCard';
import ServiceAdminCard from '../../component/ServiceAdminCard';
import Adminnav from './AdminNav';

export default function AdminHome() {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [companies, setCompany] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [serviceResponse, productResponse, companyResponse] =
        await Promise.all([
          axios.get('/api/service'),
          axios.get('/api/product'),
          axios.get('/api/company'),
        ]);
      setServices(serviceResponse.data);
      setProducts(productResponse.data);
      setCompany(companyResponse.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 font-roboto flex flex-col">
      <Adminnav />

      {/* Main Content */}
      <div className="flex-1 flex md:ml-[255px] flex-col">
        {/* Title Section */}
        <motion.div
          className="w-full bg-gray-900 flex justify-between items-center p-3 pl-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h1 className="text-2xl  pl-5  sm:text-3xl py-2 font-semibold text-white ">
            Admin Home
          </h1>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/"
              className="flex items-center text-white hover:text-gray-400 transition"
            >
              <FaArrowLeft className="mr-2" />
              Back to home
            </Link>
          </motion.div>
        </motion.div>

        {loading ? (
          <motion.div
            className="w-full space-y-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {/* Products Loading Skeleton */}
            <div className="bg-white shadow-xl  overflow-hidden">
              <div className="bg-gray-800 p-6">
                <div className="h-8 w-1/3 bg-gray-700 rounded animate-pulse"></div>
              </div>
              <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {[...Array(4)].map((_, i) => (
                  <div
                    key={i}
                    className="h-64 bg-gray-100 rounded-lg animate-pulse"
                  ></div>
                ))}
              </div>
            </div>

            {/* Services Loading Skeleton */}
            <div className="bg-white shadow-xl  overflow-hidden">
              <div className="bg-gray-800 p-6">
                <div className="h-8 w-1/3 bg-gray-700 rounded animate-pulse"></div>
              </div>
              <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {[...Array(3)].map((_, i) => (
                  <div
                    key={i}
                    className="h-64 bg-gray-100 rounded-lg animate-pulse"
                  ></div>
                ))}
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.div
            className="w-full  space-y-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {/* Products Section */}
            <motion.div
              className="bg-white shadow-xl  overflow-hidden"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <div className="bg-gray-800 p-6 text-white">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl sm:text-3xl font-bold">Products</h2>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link
                      to="/post/product"
                      className="flex items-center px-4 py-2 bg-gray-200 hover:bg-gray-400 text-black rounded-lg transition"
                    >
                      <FaPlus className="mr-2" />
                      Add Product
                    </Link>
                  </motion.div>
                </div>
              </div>
              <div className="p-6">
                {products.length === 0 ? (
                  <div className="text-center py-12 text-gray-500">
                    <p className="text-lg">No products found</p>
                    <Link
                      to="/admin/post/product"
                      className="text-indigo-600 hover:text-indigo-800 mt-2 inline-block"
                    >
                      Add your first product
                    </Link>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {products.map((product) => (
                      <motion.div
                        key={product._id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <ProductAdminCard fetch={fetchData} item={product} />
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>

            {/* Services Section */}
            <motion.div
              className="bg-white shadow-xl overflow-hidden"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <div className="bg-gray-800 p-6 text-white">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl sm:text-3xl font-bold">Services</h2>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link
                      to="/post/service"
                      className="flex items-center px-4 py-2 bg-gray-200 hover:bg-gray-400 text-black rounded-lg transition"
                    >
                      <FaPlus className="mr-2" />
                      Add Service
                    </Link>
                  </motion.div>
                </div>
              </div>
              <div className="p-6">
                {services.length === 0 ? (
                  <div className="text-center py-12 text-gray-500">
                    <p className="text-lg">No services found</p>
                    <Link
                      to="/post/service"
                      className="text-indigo-600 hover:text-indigo-800 mt-2 inline-block"
                    >
                      Add your first service
                    </Link>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6">
                    {services.map((service) => (
                      <motion.div
                        key={service._id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <ServiceAdminCard fetch={fetchData} item={service} />
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>

            <motion.div
              className="bg-white shadow-xl overflow-hidden"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <div className="bg-gray-800 p-6 text-white">
                <div className="flex justify-between items-center">
                  <h2 className="text-2xl sm:text-3xl font-bold">Companies</h2>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link
                      to="/post/company"
                      className="flex items-center px-4 py-2 bg-gray-200 hover:bg-gray-400 text-black rounded-lg transition"
                    >
                      <FaPlus className="mr-2" />
                      Add Company
                    </Link>
                  </motion.div>
                </div>
              </div>
              <div className="p-6">
                {companies.length === 0 ? (
                  <div className="text-center py-12 text-gray-500">
                    <p className="text-lg">No companies found</p>
                    <Link
                      to="/post/company"
                      className="text-indigo-600 hover:text-indigo-800 mt-2 inline-block"
                    >
                      Add your first Company
                    </Link>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6">
                    {companies.map((service) => (
                      <motion.div
                        key={service._id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <CompanyAdminCard fetch={fetchData} item={service} />
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
