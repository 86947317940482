import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useUser from '../zustand/useUser';

export default function ProtectedAdmin() {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);
  const fetchUserStatus = async () => {
    try {
      const response = await fetch('/api/auth/check-admin-status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        throw new Error('Failed to verify user status');
      }
    } catch (error) {
      console.error('Error fetching user status:', error);
      setUser(null);
    }
  };
  useEffect(() => {
    const checkUserStatus = async () => {
      setLoading(true);
      try {
        await fetchUserStatus();
      } catch (error) {
        console.error('Failed to fetch admin status:', error);
      }
      setLoading(false);
    };

    checkUserStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? <Outlet /> : <Navigate to="/" />;
}
