import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import frd from '../assets/frd.png';
import hifi from '../assets/hifi.png';
import logo from '../assets/logo1.png';
import man from '../assets/man.png';
import robi from '../assets/robi.png';
import Footer from '../component/Footer';
import NewImageSlider from '../component/imageSliderNew';
import InfiniteScroller from '../component/infiniteScroller';
import Navigation from '../component/Navigation';
import VideoCarousel from '../component/VideoCarousel';

const icons = [hifi, robi, frd, man];
export default function Home() {
  const [products, setProducts] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true); // Add loading state
  const [currentIndex, setCurrentIndex] = useState(0);
  const [company, setCompany] = useState([]);

  const nextProduct = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === products.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevProduct = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/api/product');
        const responseCompany = await axios.get('/api/company');
        setCompany(responseCompany.data);
        setProducts(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchProducts();
  }, []);
  return (
    <div className="min-h-screen  overflow-x-hidden w-[100%] bg-[#F8F9FA] font-african">
      <motion.div
        initial={{ y: '-100%', opacity: 0 }} // Start off-screen above
        animate={{ y: 0, opacity: 1 }} // End at the original position
        transition={{
          type: 'spring', // Smooth spring animation
          stiffness: 80, // Snappy start
          damping: 25, // Smooth stop
          duration: 1.5, // Duration of animation
        }}
      >
        <Navigation />
      </motion.div>

      <NewImageSlider />

      <InfiniteScroller icons={company} />

      <div className="flex flex-col bg-gray-800 text-white justify-center items-center p-5 md:p-10 w-full font-african text-xl md:text-2xl">
        <div className="w-full md:w-[80%] space-y-10">
          {/* First section - Image left, text right */}
          <div className="flex flex-col xl:flex-row border-b border-gray-500 pb-4 gap-6 md:gap-10 items-center">
            <img
              src={logo}
              className="w-full xl:w-[500px] bg-[#F8F9FA] p-10 h-auto object-cover shadow-lg"
              alt="BARATON Group logo"
            />
            <div className="space-y-4">
              BARATON Group began operating in 2007, as importer and distributor
              of Tata-Daewoo truck spare parts and heavy construction machinery
              rental in Ethiopia. Over the next 17 years, the company grew
              significantly, diversifying its operations to include the import
              and sale of a variety of products.
            </div>
          </div>
          <div>
            These included FURKAWA Rock Drilling Machines from Japan, AIRMAN Air
            Compressors from Japan, HIFI Filtration Solutions from Switzerland,
            and ROBIT Rock Drilling Accessories from Finland. The company also
            ventured into construction sales and rental of real estate
            properties in the US, Kenya, and Ethiopia.
          </div>

          {/* Second section - Text left, image right (add your image source) */}
          <div className="flex flex-col md:flex-row-reverse gap-6 md:gap-10 items-center">
            <div className="space-y-4">
              <p>
                In 2019, BARATON Group expanded further by setting up a
                USA-based subsidiary, BARATON Trade LLC, and a woodworking
                factory. This marked the transition to the BARATON Group.
              </p>
              <p>
                Since its formation, the BARATON Group has strengthened its
                customer services and broadened its resource access in key hub
                countries. Today, the group provides a wide array of services,
                including rock drilling, compressed air systems, filtration,
                construction machinery attachments, and real estate.
              </p>
              <div className="flex gap-4">
                <div className="mt-6">
                  <Link
                    to="/about"
                    className="inline-block px-6 py-3 bg-gray-700 text-white font-medium  hover:bg-gray-700 transition duration-300 shadow-md hover:shadow-lg"
                  >
                    Learn more
                  </Link>
                </div>
                <div className="mt-6">
                  <Link
                    to="/products"
                    className="inline-block px-6 py-3 bg-gray-700 text-white font-medium  hover:bg-gray-700 transition duration-300 shadow-md hover:shadow-lg"
                  >
                    Our Products
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* commented about us  */}

      {/* <div className='text-[#0D5380] pt-20'>
                <motion.div
                    className='text-5xl font-bold text-center py-4'
                    initial={{ x: '-100%', opacity: 0 }}  // Start from the left off-screen
                    whileInView={{ x: 0, opacity: 1 }}    // Animate to the original position when in view
                    viewport={{ once: true }}              // Trigger animation once when in the viewport
                    transition={{
                        type: 'spring',                       // Smooth spring animation
                        stiffness: 80,                        // Snappy start
                        damping: 25,                          // Smooth stop
                        duration: 1.5,                        // Duration of animation
                    }}
                >
                    ABOUT US
                </motion.div>

                <motion.div
                    className='text-center px-56 text-2xl'
                    initial={{ x: '100%', opacity: 0 }}  // Start from the right off-screen
                    whileInView={{ x: 0, opacity: 1 }}    // Animate to the original position when in view
                    viewport={{ once: true }}              // Trigger animation once when in the viewport
                    transition={{
                        type: 'spring',                       // Smooth spring animation
                        stiffness: 80,                        // Snappy start
                        damping: 25,                          // Smooth stop
                        duration: 1.5,                        // Duration of animation
                    }}
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                </motion.div>
            </div> */}
      <div className="text-[#0D5380] md:pt-16 ">
        <VideoCarousel />
      </div>

      <Footer />
    </div>
  );
}
