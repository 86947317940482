import React from 'react';
import {
  BrowserRouter,
  matchPath,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Navigation from './component/Navigation';
import ProtectedAdmin from './component/ProtectedAdmin';
import AboutUs from './Page/About';
import AdminHome from './Page/Admin/AdminHome';
import AdminMessages from './Page/Admin/AdminMessage';
import EditCompany from './Page/Admin/editCompany';
import EditProducts from './Page/Admin/EditProduct';
import EditService from './Page/Admin/EditService';
import PostCompany from './Page/Admin/postCompany';
import PostProducts from './Page/Admin/PostProducts';
import PostService from './Page/Admin/PostService';
import ForgotPassword from './Page/auth/Forgetpassword';
import Login from './Page/auth/Login';
import ResetPassword from './Page/auth/Resetpassword';
import CompanyDetailPage from './Page/comapnyDetail';
import ContactMe from './Page/ContractMe';
import Home from './Page/Home';
import Products from './Page/Products';
import ServicesPage from './Page/services';
import ViewProduct from './Page/ViewProduct';

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer />

      <Routes>
        <Route element={<ProtectedAdmin />}>
          <Route path="/post/product" element={<PostProducts />}></Route>
          <Route path="/post/service" element={<PostService />}></Route>
          <Route path="/post/message" element={<AdminMessages />}></Route>
          <Route path="/post/company" element={<PostCompany />}></Route>
          <Route path="/edit/company/:id" element={<EditCompany />}></Route>

          <Route path="/edit/product/:id" element={<EditProducts />}></Route>
          <Route path="/edit/service/:id" element={<EditService />}></Route>
          <Route path="/admin" element={<AdminHome />}></Route>
        </Route>

        <Route path="/" element={<Home />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/product/:id" element={<ViewProduct />}></Route>
        <Route path="/comapny/:id" element={<CompanyDetailPage />}></Route>

        <Route path="/service" element={<ServicesPage />}></Route>
        <Route
          path="/reset-password/:token"
          element={<ResetPassword />}
        ></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/contact" element={<ContactMe />}></Route>
        <Route path="/about" element={<AboutUs />}></Route>
      </Routes>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}

function NavConditional() {
  const location = useLocation();

  // Check if the current path matches `/projects/ui/:id`
  const isFigmaPage = matchPath('/projects/ui/:id', location.pathname);

  if (isFigmaPage) {
    return null;
  }

  return <Navigation />;
}
