export default function searchProducts(searchTerm, products) {
    // Return all products if search term is empty
    if (!searchTerm.trim()) return products;

    const term = searchTerm.toLowerCase();

    return products.filter(product => {
        // Check name
        if (product.title.toLowerCase().includes(term)) return true;

        // Check description
        if (product.shortDescription.toLowerCase().includes(term)) return true;

        // Check category
        if (product.content.toLowerCase().includes(term)) return true;

        if (product.country.toLowerCase().includes(term)) return true;


        return false;
    });
}