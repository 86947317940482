import React, { useEffect, useState } from "react";
import { FaShare } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Navigation from "../component/Navigation";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from 'react-spinners';
import useUser from "../zustand/useUser";

export default function Prods() {
    const { product } = useUser();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="w-full bg-[#F8F9FA] overflow-x-hidden">
            <div className="px-4 xl:px-6 w-full">
                <motion.div
                    initial={{ y: '-100%', opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: 'spring',
                        stiffness: 80,
                        damping: 25,
                        duration: 1.5,
                    }}
                >
                    <Navigation value={true} />
                </motion.div>
            </div>

            <div className="mt-10 xl:mt-20">
                {loading ? (
                    <div className="flex justify-center items-center min-h-[70vh]">
                        <ClipLoader color="#0D5380" size={50} />
                    </div>
                ) : (
                    product?.map((prod, index) => (
                        <div key={index} className='p-4 xl:p-32 flex flex-col xl:flex-row xl:justify-between pt-10 xl:pt-20'>
                            {index % 2 === 0 ? (
                                <div className="xl:border-none flex xl:flex-row flex-col w-full justify-between border xl:p-0 p-4 xl:shadow-none shadow-2xl rounded-2xl">
                                    <motion.div
                                        className='relative w-full xl:w-[500px] h-[300px] xl:h-[450px] bg-gray-300  mb-8 xl:mb-0'
                                        initial={{ x: '-100%', opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        viewport={{ once: true }}
                                        transition={{
                                            type: 'spring',
                                            stiffness: 80,
                                            damping: 25,
                                            duration: 1.5,
                                        }}
                                    >
                                        <img
                                            className='absolute bottom-10 xl:bottom-20 bg-gray-300  left-4 xl:left-24  object-cover border-[1px] border-gray-300 w-[90%] xl:w-[500px] shadow-image h-[250px] xl:h-[450px]'
                                            src={prod.image}
                                            alt={prod.title}
                                        />
                                        <div className='absolute top-6 right-8  xl:-top-16 xl:-right-20 h-8 w-8 flex items-center justify-center p-2 rounded-full bg-[#c1d3e0]'>
                                            <FaShare className='text-xl' />
                                        </div>
                                        <div className='absolute bottom-12 left-6 xl:bottom-24 xl:left-28 text-sm px-3 py-1 bg-[#c1d3e0] rounded-full'>
                                            {prod.country}
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        className='text-gray-900  w-full xl:w-[40%]'
                                        initial={{ x: '100%', opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        viewport={{ once: true }}
                                        transition={{
                                            type: 'spring',
                                            stiffness: 80,
                                            damping: 25,
                                            duration: 1.5,
                                        }}
                                    >
                                        <div className='text-3xl xl:text-5xl font-bold'>
                                            {prod.title}
                                        </div>
                                        <div className='text-xl xl:text-3xl flex flex-col pt-4 xl:pt-8'>
                                            {prod.shortDescription}
                                            <Link
                                                to={`/product/${prod._id}`}
                                                className="text-black pt-2 xl:pt-4 text-lg xl:text-xl font-semibold underline hover:text-red-500 transition-colors"
                                            >
                                                Read More
                                            </Link>
                                        </div>
                                    </motion.div>
                                </div>
                            ) : (
                                <div className="xl:border-none border flex xl:flex-row flex-col w-full justify-between xl:p-0 p-4 xl:shadow-none shadow-2xl ">
                                    <motion.div
                                        className='text-gray-900 w-full  xl:w-[40%] order-2 xl:order-1'
                                        initial={{ x: '-100%', opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        viewport={{ once: true }}
                                        transition={{
                                            type: 'spring',
                                            stiffness: 80,
                                            damping: 25,
                                            duration: 1.5,
                                        }}
                                    >
                                        <div className='text-3xl xl:text-5xl font-bold'>
                                            {prod.title}
                                        </div>
                                        <div className='text-xl xl:text-3xl flex flex-col pt-4 xl:pt-8'>
                                            {prod.shortDescription}
                                            <Link
                                                to={`/product/${prod._id}`}
                                                className="text-black pt-2 xl:pt-4 text-lg xl:text-xl font-semibold underline hover:text-red-500 transition-colors"
                                            >
                                                Read More
                                            </Link>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        className='relative w-full xl:w-[500px] h-[300px] xl:h-[450px] bg-gray-300   mb-8 xl:mb-0 order-1 xl:order-2'
                                        initial={{ x: '100%', opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        viewport={{ once: true }}
                                        transition={{
                                            type: 'spring',
                                            stiffness: 80,
                                            damping: 25,
                                            duration: 1.5,
                                        }}
                                    >
                                        <img
                                            className='absolute bottom-10 xl:bottom-20 bg-gray-300 right-4 xl:right-24 object-cover  border-[1px] border-gray-300 w-[90%] xl:w-[500px] shadow-image-left h-[250px] xl:h-[450px] '
                                            src={prod.image}
                                            alt={prod.title}
                                        />
                                        <div className='absolute  top-6 left-6 xl:-top-16 xl:-left-20 h-8 w-8 flex items-center justify-center p-2 rounded-full bg-[#c1d3e0]'>
                                            <FaShare className='text-xl' />
                                        </div>
                                        <div className='absolute bottom-12 right-6 xl:bottom-24 xl:right-28 text-sm px-3 py-1 bg-[#c1d3e0] rounded-full'>
                                            {prod.country}
                                        </div>
                                    </motion.div>
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
            <Footer />
        </div>
    );
}