import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Footer from '../component/Footer';
import Navigation from '../component/Navigation';

const ServicesPage = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('/api/service');
        setServices(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchServices();
  }, []);

  return (
    <div className="min-h-screen bg-[#F8F9FA]">
      <div className="h-screen w-full overflow-x-hidden text-white flex pt-0 flex-col items-center ">
        <div className="px-6 w-full">
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 10,
              duration: 0.5,
            }}
          >
            <Navigation />
          </motion.div>
        </div>

        <div className="max-w-8xl md:px-24 mx-auto px-4 sm:px-6 py-8 w-full">
          <motion.h2
            className="text-2xl md:text-4xl font-bold text-gray-700 mb-8 sm:mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            Our Services
          </motion.h2>

          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
              {services?.map((service, index) => (
                <motion.div
                  key={service._id}
                  className="bg-gray-800  shadow-lg p-6 md:py-16 md:p-10 transition-all duration-300 hover:scale-[1.02]"
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true, margin: '0px 0px -50px 0px' }}
                  transition={{
                    duration: 0.6,
                    delay: index * 0.1,
                    ease: 'easeOut',
                  }}
                >
                  <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 pb-6">
                    {service.image && (
                      <motion.img
                        src={`${service.image}`}
                        className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full"
                        alt={service.title}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ delay: index * 0.1 + 0.3 }}
                      />
                    )}
                    <h3 className="text-xl sm:text-2xl md:text-3xl font-bold text-white">
                      {service.title}
                    </h3>
                  </div>
                  <p className="text-base sm:text-lg md:text-xl text-white mb-4">
                    {service.shortDescription}
                  </p>
                  <ul className="list-disc list-inside mt-2 space-y-1 sm:space-y-2 text-base sm:text-lg md:text-xl text-white pl-5">
                    {service.contentList.map((content, i) => (
                      <motion.li
                        key={i}
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: i * 0.1 + 0.4 }}
                      >
                        {content}
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
