import { useState, useRef, useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function PremiumVideoSlider() {
    const videos = [
        {
            url: "https://www.youtube.com/embed/pyoNPfUgJJo",
            thumbnail: "https://img.youtube.com/vi/pyoNPfUgJJo/maxresdefault.jpg",
            title: "Product Showcase",
            description: "Latest innovations"
        },
        {
            url: "https://www.youtube.com/embed/uQlNhObyLu8?si=8xMM4cHPNpA-W15W",
            thumbnail: "https://img.youtube.com/vi/uQlNhObyLu8/maxresdefault.jpg",
            title: "Product Showcase",
            description: "Latest innovations"
        },
        {
            url: "https://www.youtube.com/embed/gnwQx7AC2ws",
            thumbnail: "https://img.youtube.com/vi/gnwQx7AC2ws/maxresdefault.jpg",
            title: "Behind the Scenes",
            description: "Our production process"
        },
        {
            url: "https://www.youtube.com/embed/C8uXNLChKuo",
            thumbnail: "https://img.youtube.com/vi/C8uXNLChKuo/maxresdefault.jpg",
            title: "Customer Stories",
            description: "Client success experiences"
        },
        {
            url: "https://www.youtube.com/embed/q9M_eFAnwPs",
            thumbnail: "https://img.youtube.com/vi/q9M_eFAnwPs/maxresdefault.jpg",
            title: "Innovation in Action",
            description: "See our groundbreaking work"
        },
        {
            url: "https://www.youtube.com/embed/AJSPVh_fCoY",
            thumbnail: "https://img.youtube.com/vi/AJSPVh_fCoY/maxresdefault.jpg",
            title: "Our Team Culture",
            description: "Meet the people"
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(null);
    const [thumbnailsLoaded, setThumbnailsLoaded] = useState(false);
    const [direction, setDirection] = useState(0);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "0px 0px -100px 0px" });

    // Preload all thumbnails
    useEffect(() => {
        const loadThumbnails = async () => {
            const loadingPromises = videos.map(video => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = video.thumbnail;
                    img.onload = () => resolve(true);
                    img.onerror = () => {
                        const fallbackSrc = `https://img.youtube.com/vi/${video.url.split('/embed/')[1]}/hqdefault.jpg`;
                        img.src = fallbackSrc;
                        resolve(true);
                    };
                });
            });

            await Promise.all(loadingPromises);
            setThumbnailsLoaded(true);
        };

        loadThumbnails();
    }, []);

    // Animation controls
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        }
    }, [isInView, controls]);

    const getVisibleVideos = () => {
        return [
            videos[(currentIndex - 1 + videos.length) % videos.length],
            videos[currentIndex],
            videos[(currentIndex + 1) % videos.length]
        ];
    };

    const goToNext = () => {
        setDirection(1);
        setCurrentIndex(prev => (prev + 1) % videos.length);
        setIsPlaying(null);
    };

    const goToPrev = () => {
        setDirection(-1);
        setCurrentIndex(prev => (prev - 1 + videos.length) % videos.length);
        setIsPlaying(null);
    };

    const handlePlay = (index) => {
        setIsPlaying(index === 1 ? currentIndex :
            index === 0 ? (currentIndex - 1 + videos.length) % videos.length :
                (currentIndex + 1) % videos.length);
    };

    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut",
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                ease: "easeOut"
            }
        }
    };

    const cardVariants = {
        left: {
            initial: { x: -100, opacity: 0 },
            animate: { x: 0, opacity: 0.8, transition: { duration: 0.5 } },
            exit: { x: -100, opacity: 0, transition: { duration: 0.3 } }
        },
        middle: {
            initial: { y: 50, opacity: 0 },
            animate: { y: 0, opacity: 1, transition: { duration: 0.7 } },
            exit: { y: 50, opacity: 0, transition: { duration: 0.3 } }
        },
        right: {
            initial: { x: 100, opacity: 0 },
            animate: { x: 0, opacity: 0.8, transition: { duration: 0.5 } },
            exit: { x: 100, opacity: 0, transition: { duration: 0.3 } }
        }
    };

    const visibleVideos = getVisibleVideos();

    return (
        <motion.div
            ref={ref}
            className="w-full py-20 bg-gray-100"
            initial="hidden"
            animate={controls}
            variants={containerVariants}
        >
            <div className="max-w-7xl mx-auto px-4">
                <motion.h2
                    className="text-4xl text-black font-bold text-center mb-4"
                    variants={itemVariants}
                >
                    Featured Videos
                </motion.h2>
                <motion.p
                    className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto"
                    variants={itemVariants}
                >
                    Discover our work through these immersive experiences
                </motion.p>

                {!thumbnailsLoaded ? (
                    <div className="h-[70vh] flex items-center justify-center">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
                    </div>
                ) : (
                    <motion.div
                        className="relative h-[70vh]"
                        variants={itemVariants}
                    >
                        {/* Navigation Arrows */}
                        <motion.button
                            onClick={goToPrev}
                            className="absolute left-4 top-1/2 -translate-y-1/2 z-20 bg-white/80 hover:bg-white p-4 rounded-full shadow-lg transition-all duration-300"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </motion.button>

                        {/* Video Slider with smooth transitions */}
                        <div className="flex items-center justify-center h-full gap-6">
                            {visibleVideos.map((video, index) => (
                                <motion.div
                                    key={`${video.url}-${currentIndex}-${index}`}
                                    className={`relative ${index === 1
                                        ? "w-[150%] h-full z-10"
                                        : "w-1/3 h-3/4 z-0 hidden md:block"
                                        }`}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    variants={
                                        index === 0 ? cardVariants.left :
                                            index === 1 ? cardVariants.middle :
                                                cardVariants.right
                                    }
                                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                                >
                                    <div className={`relative w-full h-full rounded-2xl overflow-hidden shadow-2xl bg-black ${index === 1 ? "" : "scale-90 opacity-80"}`}>
                                        {isPlaying === (index === 1 ? currentIndex :
                                            index === 0 ? (currentIndex - 1 + videos.length) % videos.length :
                                                (currentIndex + 1) % videos.length) ? (
                                            <iframe
                                                className="w-full h-full"
                                                src={`${video.url}?autoplay=1&mute=1`}
                                                title={video.title}
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        ) : (
                                            <>
                                                <img
                                                    src={video.thumbnail}
                                                    alt={video.title}
                                                    className="w-full h-full object-cover"
                                                    onError={(e) => {
                                                        e.target.src = `https://img.youtube.com/vi/${video.url.split('/embed/')[1]}/hqdefault.jpg`;
                                                    }}
                                                />
                                                {index === 1 && (
                                                    <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
                                                        <motion.button
                                                            onClick={() => handlePlay(index)}
                                                            className="bg-white text-black hover:bg-gray-200 p-4 rounded-full transition-all"
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}
                                                        >
                                                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                        </motion.button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </motion.div>
                            ))}
                        </div>

                        <motion.button
                            onClick={goToNext}
                            className="absolute right-4 top-1/2 -translate-y-1/2 z-20 bg-white/80 hover:bg-white p-4 rounded-full shadow-lg transition-all duration-300"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </motion.button>
                    </motion.div>
                )}

                {/* Indicators */}
                <motion.div
                    className="flex justify-center mt-8 gap-2"
                    variants={itemVariants}
                >
                    {videos.map((_, index) => (
                        <motion.button
                            key={index}
                            onClick={() => {
                                setDirection(index > currentIndex ? 1 : -1);
                                setCurrentIndex(index);
                            }}
                            className={`w-3 h-3 rounded-full transition-all ${index === currentIndex ? 'bg-black' : 'bg-gray-400 hover:bg-gray-600'}`}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            animate={{
                                scale: index === currentIndex ? 1.3 : 1
                            }}
                        />
                    ))}
                </motion.div>
            </div>
        </motion.div>
    );
}