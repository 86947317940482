import axios from 'axios';
import { useState } from 'react';
import {
  FaBars,
  FaComment,
  FaConciergeBell,
  FaHome,
  FaSignOutAlt,
  FaTimes,
  FaUtensils,
} from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useUser from '../../zustand/useUser';

const Adminnav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useUser();
  const toggleNav = () => setIsOpen(!isOpen);
  const closeNav = () => setIsOpen(false);

  const generalItems = [
    { name: 'Dashboard', icon: <FaHome />, link: '/admin' },
  ];

  const operationsItems = [
    { name: 'Post Products', icon: <FaConciergeBell />, link: '/post/product' },
    { name: 'Post Services', icon: <FaUtensils />, link: '/post/service' },
    { name: 'Post Company', icon: <FaUtensils />, link: '/post/company' },

    { name: 'Messages', icon: <FaComment />, link: '/post/message' },
  ];

  const handleLogout = async () => {
    await axios.post(`/api/auth/logout`);
    setUser(null);
    navigate('/login');
  };

  const renderNavItems = (items) => (
    <ul className="flex-1">
      {items.map((item) => {
        const isActive = location.pathname === item.link;
        return (
          <li key={item.name}>
            <Link
              to={item.link}
              className={`flex items-center p-2 py-2 transition duration-200
                                ${
                                  isActive
                                    ? 'bg-gradient-to-r from-gray-600 to-gray-900 text-white border-l border-gray-500'
                                    : 'hover:bg-gray-600 hover:border-l border-gray=400 hover:text-white'
                                }`}
              onClick={closeNav}
            >
              {item.icon}
              <span className="ml-4">{item.name}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      {/* Hamburger Menu Icon */}
      <div className="lg:hidden fixed top-6 left-4 z-50">
        <button onClick={toggleNav} className="text-white  focus:outline-none">
          <FaBars className="text-2xl" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`font-african flex z-50 flex-col bg-gray-900 ${
          isOpen ? 'w-64' : 'w-0 lg:w-64'
        } shadow-lg h-screen transition-all duration-300 fixed`}
      >
        <div
          className={`rounded-xl pt-4 px-5 text-white flex-col ${
            isOpen ? 'flex' : 'hidden lg:flex'
          }`}
        >
          {/* Close Button (Visible on small screens) */}
          <div className="lg:hidden flex justify-end">
            <button
              onClick={closeNav}
              className="text-white focus:outline-none"
            >
              <FaTimes className="text-2xl" />
            </button>
          </div>

          <div className="flex flex-col justify-between min-h-[98vh]">
            <div>
              <div className="text-2xl pb-3 border-b border-gray-700 font-bold">
                Baraton <span className="text-white"> Admin</span>
              </div>

              <div className="mt-6">
                <div className="text-xl mb-3 text-gray-300">General</div>
                {renderNavItems(generalItems)}
              </div>

              <div className="mt-6">
                <div className="text-xl mb-3 text-gray-300">Operations</div>
                {renderNavItems(operationsItems)}
              </div>
            </div>
            <div>
              <div className="flex mt-10 pt-8 justify-between">
                <div
                  onClick={() => handleLogout()}
                  className="flex  text-white bg-red-500 px-3 py-1 rounded-lg items-center pl-4 gap-2 cursor-pointer"
                >
                  <FaSignOutAlt className="text-xl" />
                  Logout
                </div>
              </div>
              <div className="p-4 pl-0 border-t border-gray-500 mt-5">
                <p className="text-white text-sm">
                  &copy; 2025 Baraton Bussiness Group
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Overlay for small screens */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={closeNav} // Close sidebar when overlay is clicked
        ></div>
      )}
    </>
  );
};

export default Adminnav;
