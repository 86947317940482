import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import {
  FaArrowLeft,
  FaEnvelope,
  FaEnvelopeOpen,
  FaPhone,
  FaSearch,
  FaTrash,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Adminnav from './AdminNav';

import { toast } from 'react-toastify';

export default function AdminMessages() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);

  const timeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) return 'Just now';
    if (seconds < 3600) return `${Math.floor(seconds / 60)} min ago`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)} hr ago`;
    if (seconds < 604800)
      return `${Math.floor(seconds / 86400)} day${
        seconds < 172800 ? '' : 's'
      } ago`;
    if (seconds < 2592000)
      return `${Math.floor(seconds / 604800)} week${
        seconds < 1209600 ? '' : 's'
      } ago`;
    if (seconds < 31536000)
      return `${Math.floor(seconds / 2592000)} month${
        seconds < 5184000 ? '' : 's'
      } ago`;
    return `${Math.floor(seconds / 31536000)} year${
      seconds < 63072000 ? '' : 's'
    } ago`;
  };

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get('/api/message');
      setMessages(data.data);
    } catch (error) {
      toast.error('Failed to load messages');
      console.error('Fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const filteredMessages = messages.filter(
    (message) =>
      message.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        await axios.delete(`/api/message/${id}`);
        setMessages(messages.filter((msg) => msg._id !== id));
        if (selectedMessage?._id === id) setSelectedMessage(null);
        toast.success('Message deleted successfully');
      } catch (error) {
        toast.error('Failed to delete message');
        console.error('Delete error:', error);
      }
    }
  };

  const markAsRead = async (id) => {
    try {
      await axios.patch(`/api/message/${id}`, {
        isRead: true,
      });
      setMessages(
        messages.map((msg) => (msg._id === id ? { ...msg, isRead: true } : msg))
      );
      if (selectedMessage?._id === id) {
        setSelectedMessage({ ...selectedMessage, isRead: true });
      }
    } catch (error) {
      console.error('Mark as read error:', error);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 font-roboto flex flex-col">
      <Adminnav />

      {/* Main Content */}
      <div className="flex-1 flex md:ml-[255px] flex-col">
        {/* Title Section */}
        <motion.div
          className="w-full bg-gray-900 flex justify-between items-center p-3 pl-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <h1 className="text-2xl  pl-5  sm:text-3xl py-2 font-semibold text-white ">
            Messages From Contact Us
          </h1>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/admin"
              className="flex items-center text-white transition"
            >
              <FaArrowLeft className="mr-2" />
              Back to admin
            </Link>
          </motion.div>
        </motion.div>
        {/* Messages Container */}
        <motion.div
          className="w-full min-h-[91vh] bg-white shadow-xl overflow-hidden"
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col lg:flex-row">
            {/* Message List Sidebar */}
            <motion.div
              className="w-full lg:w-2/5 min-h-[91vh] bg-gray-800 p-8 sm:p-10 lg:p-12 text-white"
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold mb-6">
                Message Inbox
              </h2>
              <p className="text-indigo-100 mb-8 text-lg">
                {filteredMessages.length} filtered messages
              </p>

              <div className="relative mb-6">
                <input
                  type="text"
                  placeholder="Search messages..."
                  className="w-full px-4 py-3 pl-10 bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-gray-500 focus:border-gray-500 text-white placeholder-gray-300 transition"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch className="absolute left-3 top-4 text-gray-400" />
              </div>

              {loading ? (
                <div className="space-y-4">
                  {[...Array(5)].map((_, i) => (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 0.5 }}
                      transition={{ duration: 0.3 }}
                      className="h-16 bg-gray-700 rounded-lg animate-pulse"
                    ></motion.div>
                  ))}
                </div>
              ) : filteredMessages.length === 0 ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="text-center py-8"
                >
                  <FaEnvelopeOpen className="mx-auto text-3xl text-indigo-300 mb-4" />
                  <p className="text-indigo-100">No messages found</p>
                </motion.div>
              ) : (
                <motion.ul
                  className="divide-y divide-gray-700 max-h-[calc(100vh-350px)] overflow-y-auto"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  {filteredMessages.map((message) => (
                    <motion.li
                      key={message._id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`py-4 px-2 hover:bg-gray-700 cursor-pointer transition-colors ${
                        !message.isRead ? 'bg-gray-700' : ''
                      } ${
                        selectedMessage?._id === message._id
                          ? 'bg-gray-900'
                          : ''
                      }`}
                      onClick={() => {
                        setSelectedMessage(message);
                        if (!message.isRead) markAsRead(message._id);
                      }}
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1 min-w-0">
                          <h3 className="font-medium text-white truncate">
                            {message.name}
                          </h3>
                          <p className="text-sm text-indigo-200 truncate">
                            {message.email}
                          </p>
                        </div>
                        <div className="flex items-center space-x-2 ml-2">
                          {!message.isRead && (
                            <span className="h-2 w-2 rounded-full bg-indigo-400"></span>
                          )}
                          <span className="text-xs text-gray-400 whitespace-nowrap">
                            {timeAgo(message.createdAt)}
                          </span>
                        </div>
                      </div>
                      <p className="text-sm mt-1 text-gray-300 line-clamp-1">
                        {message.message}
                      </p>
                    </motion.li>
                  ))}
                </motion.ul>
              )}
            </motion.div>

            {/* Message Detail Panel */}
            <motion.div
              className="w-full lg:w-3/5 p-8 sm:p-10 lg:p-12"
              initial={{ x: 20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, ease: 'easeOut' }}
            >
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6">
                Message Details
              </h2>

              {selectedMessage ? (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="space-y-6"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-2xl font-bold text-gray-800">
                        {selectedMessage.name}
                      </h3>
                      <div className="flex items-center mt-2 text-gray-600">
                        <FaEnvelope className="mr-2 text-gray-400" />
                        <a
                          href={`mailto:${selectedMessage.email}`}
                          className="hover:text-indigo-600"
                        >
                          {selectedMessage.email}
                        </a>
                      </div>
                      {selectedMessage.phone && (
                        <div className="flex items-center mt-2 text-gray-600">
                          <FaPhone className="mr-2 text-gray-400" />
                          <a
                            href={`tel:${selectedMessage.phone}`}
                            className="hover:text-indigo-600"
                          >
                            {selectedMessage.phone}
                          </a>
                        </div>
                      )}
                      <div className="mt-2 text-sm text-gray-500">
                        {new Date(selectedMessage.createdAt).toLocaleString()}
                        <span className="ml-2 bg-indigo-100 text-indigo-800 text-xs px-2 py-1 rounded">
                          {timeAgo(selectedMessage.createdAt)}
                        </span>
                      </div>
                    </div>
                    <motion.button
                      onClick={() => handleDelete(selectedMessage._id)}
                      className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50 transition"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      title="Delete message"
                    >
                      <FaTrash />
                    </motion.button>
                  </div>

                  <div className="pt-4 border-t border-gray-200">
                    <h4 className="text-lg font-semibold text-gray-700 mb-2">
                      Message
                    </h4>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-gray-700 whitespace-pre-line">
                        {selectedMessage.message}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                  className="text-center py-12"
                >
                  <FaEnvelopeOpen className="mx-auto text-4xl text-gray-300 mb-4" />
                  <h3 className="text-lg font-medium text-gray-700">
                    Select a message
                  </h3>
                  <p className="text-gray-500 mt-1">
                    Choose a message from the list to view details
                  </p>
                </motion.div>
              )}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
