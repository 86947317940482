import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { FiSearch, FiX } from 'react-icons/fi';
import useUser from '../zustand/useUser';
import searchProducts from '../hooks/searchProducts';
import { useNavigate } from 'react-router-dom';

const SearchBar = ({ placeholder = "Search Products..." }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { product } = useUser();
    const navigate = useNavigate()

    const handleSearch = (e) => {
        e.preventDefault();

        const results = searchProducts(searchTerm, product);
        setSearchResults(results);
    };
    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        }
    }, [searchTerm])

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            setSearchResults([]);
        }
        const results = searchProducts(e.target.value, product);
        setSearchResults(results);
    };

    const clearSearch = () => {
        setSearchTerm('');
        setSearchResults([]);
    };

    return (
        <div className="relative md:block hidden text-xl w-full max-w-md">
            <form onSubmit={handleSearch} className="relative">
                <div className="relative flex items-center">
                    <FiSearch className="absolute left-3 text-gray-400" />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        className="w-full py-2 pl-10 pr-20 border border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    />
                    {searchTerm && (
                        <button
                            type="button"
                            onClick={clearSearch}
                            className="absolute right-16 text-gray-400 hover:text-gray-600"
                        >
                            <FiX />
                        </button>
                    )}
                    <button
                        type="submit"
                        className="absolute right-0 h-full px-4 text-white bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                        <FaSearch />
                    </button>
                </div>
            </form>

            {/* Search results dropdown */}
            {searchResults.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                    <ul className="py-1">
                        {searchResults.map((product, index) => (
                            <li
                                onClick={() => {
                                    window.location.href = `/product/${product._id}`;
                                }}
                                key={index} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">

                                {product.title}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchBar;