import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../component/Navigation';
import useUser from '../../zustand/useUser';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/auth/login', { email, password });

      if (response.status === 200) {
        setUser({
          _id: response.data._id,
          name: response.data.name,
          email: response.data.email,
        });
        navigate('/admin');
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          setError('User not found. Please check your email.');
        } else if (err.response.status === 401) {
          setError('Invalid email or password.');
        } else {
          setError('An unexpected error occurred. Please try again later.');
        }
      } else if (err.request) {
        setError('Network error. Please check your internet connection.');
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex bg-gray-200 flex-col items-center  min-h-screen  ">
      <div className="px-6 w-full mb-32">
        <motion.div
          initial={{ y: '-100%', opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 80,
            damping: 25,
            duration: 1.5,
          }}
        >
          <Navigation value={true} />
        </motion.div>
      </div>
      <div className="w-full max-w-md bg-gray-300  p-8  shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
          Baraton Login
        </h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 bg-gray-200 py-2 mt-1 text-gray-900 border  focus:ring-green-500 focus:border-green-500"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 mt-1 bg-gray-200 text-gray-900 border  focus:ring-green-500 focus:border-green-500"
              placeholder="Enter your password"
              required
            />
          </div>
          <div className="text-right">
            <a
              href="/forgot-password"
              className="text-sm text-gray-600 hover:underline"
            >
              Forgot Password?
            </a>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full bg-gray-900 font-semibold text-white py-2 px-4 hover:shadow-lg transition duration-300 transform hover:scale-105  ${
              isLoading ? 'bg-gray-600 cursor-not-allowed' : 'hover:bg-gray-700'
            }`}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <span className="loader-sm"></span>
                Logging...
              </div>
            ) : (
              'Login'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
