import axios from 'axios';
import { useEffect } from 'react';
import useUser from '../zustand/useUser';

const UseFetchProductsAndServices = () => {
  const { product, service, setProduct, setService } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = [];

        if (!product) {
          requests.push(
            axios.get('/api/product').then((res) => setProduct(res.data))
          );
        }

        if (!service) {
          requests.push(
            axios.get('/api/service').then((res) => setService(res.data))
          );
        }

        await Promise.all(requests);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Optional: Add error handling here
      }
    };

    fetchData();
  }, [product, service, setProduct, setService]);

  return { product, service };
};

export default UseFetchProductsAndServices;
