import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export default function BrandScroller({ icons, title = "Brands We Work With", description = "Trusted by industry leaders worldwide" }) {
    const scrollerRef = useRef(null);
    const animationRef = useRef(null);
    const speed = 1; // Adjust speed (pixels per frame)
    const navigate = useNavigate()
    useEffect(() => {
        const scroller = scrollerRef.current;
        let position = 0;
        const singleLoopWidth = scroller.scrollWidth / 4; // Since we duplicated 4 times

        const animate = () => {
            position += speed;

            // Reset position when we've scrolled one full loop
            if (position >= singleLoopWidth) {
                position = 0;
            }

            scroller.style.transform = `translateX(-${position}px)`;
            animationRef.current = requestAnimationFrame(animate);
        };

        animationRef.current = requestAnimationFrame(animate);

        return () => {
            cancelAnimationFrame(animationRef.current);
        };
    }, [icons]);

    return (
        <div className="w-full py-8 bg-gray-50">
            {/* Header section */}
            <div className="text-center mb-8 px-4">
                <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-2">{title}</h2>
                <p className="text-lg text-gray-600 max-w-2xl mx-auto">{description}</p>
            </div>

            {/* Scroller container */}
            <div className="relative w-full overflow-hidden">
                <div
                    ref={scrollerRef}
                    className="flex w-max will-change-transform" // will-change improves performance
                >
                    {[...icons, ...icons, ...icons, ...icons].map((icon, index) => (
                        <div onClick={() => navigate(`/comapny/${icon._id}`)} key={index} className="shrink-0 mx-4 md:mx-5">
                            <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                                <img
                                    src={icon.logo_image}
                                    alt="Brand Logo"
                                    className="w-auto h-10 md:h-12 object-contain filter grayscale hover:grayscale-0 transition-all duration-300"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

// Usage example:
// <BrandScroller
//   icons={[logo1, logo2, logo3]}
//   title="Our Partners"
//   description="Collaborating with industry innovators"
// />