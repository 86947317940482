import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaGlobe, FaPlay } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';
import { useParams } from 'react-router-dom';
import Footer from '../component/Footer';
import Navigation from '../component/Navigation';

const CompanyDetailPage = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(`/api/company/${id}`);
        setCompany(response.data);
      } catch (err) {
        setError('Failed to load company details');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchCompany();
  }, [id]);

  const getYouTubeId = (url) => {
    if (!url) return null;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  if (loading)
    return (
      <div className="min-h-screen bg-gray-100 r">
        <Navigation />
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
          className="flex flex-col h-full justify-center mt-60  items-center"
        >
          <ImSpinner2 className="text-5xl text-gray-700 animate-spin" />
        </motion.div>
      </div>
    );

  if (error) return <p className="text-red-500 text-center mt-5">{error}</p>;

  const youtubeId = getYouTubeId(company.company_youtubeLink);
  const embedUrl = `https://www.youtube.com/embed/${youtubeId}`;

  return (
    <div className="min-h-screen bg-gray-100">
      <Navigation />

      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-6xl mx-auto px-4 py-10"
      >
        {/* Header Section */}
        <div className="bg-gray-100 shadow-lg rounded-lg p-6 md:flex md:items-center gap-6">
          <motion.div
            className=" w-full md:w-60 h-32 flex-shrink-0 bg-gray-100 p-2 rounded-lg shadow-md border"
            whileHover={{ scale: 1.05 }}
          >
            <img
              src={company.logo_image}
              alt={`${company.company_name} logo`}
              className="w-full h-full object-contain"
            />
          </motion.div>

          <div className="flex-1 space-y-3">
            <motion.h1 className="text-3xl font-bold text-gray-800">
              {company.company_name}
            </motion.h1>
            <p className="text-gray-600">{company.company_short_discription}</p>
            <div className="flex items-center gap-2 text-gray-700">
              <FaGlobe className="text-gray-500" />
              {company.company_country}
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="mt-10 gap-8">
          {youtubeId && (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="bg-gray-100 shadow-lg rounded-lg p-6"
            >
              <h3 className="text-lg font-semibold text-gray-800 mb-3 flex items-center gap-2">
                <FaPlay className="text-blue-500" />
                Company Video
              </h3>
              <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-md">
                <iframe
                  className="absolute top-0 left-0 w-full h-full rounded-md"
                  src={embedUrl}
                  title="Company video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </motion.div>
          )}
          {/* About Section */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="bg-gray-100 shadow-lg mt-10 rounded-lg p-6"
          >
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              About {company.company_name}
            </h2>
            <MDEditor.Markdown
              source={company?.company_description}
              style={{
                whiteSpace: 'pre-wrap',
                backgroundColor: '#f3f4f6',
                color: '#000000',
                padding: '1rem',
                borderRadius: '5px',
              }}
            />
          </motion.div>

          {/* Video Section */}
        </div>
      </motion.main>

      <Footer />
    </div>
  );
};

export default CompanyDetailPage;
